/**
 * Recaptcha button.
 *
 * Protects a form submission with Recaptcha.
 *
 * This is an invisible Recaptcha v2 implementation that will analyse user behaviour and only display a challenge if it
 * is determined that they are likely a bot. The Recaptcha amends the form submission, including a 'g-recaptcha-reponse'
 * parameter in the request - this token is included whether or not the user received a visual challenge. The token then
 * must be validated on the server end (to prevent an attacker forging the token value).
 */

import $ from '../../../../assets/js/common/jquery';


const SELECTOR = '.recaptcha-button';

// Store the last submitted form, (unfortunately in the global scope).
let $submittedRecaptchaForm = null;

$(() => {
    const $buttons = $(SELECTOR);

    $buttons.each((index, element) => {
        const $element = $(element);
        const $form = $element.closest('form');

        $form.submit((event, allowSubmit) => {
            if (allowSubmit) {
                return;
            }

            event.preventDefault();

            grecaptcha.reset();
            grecaptcha.execute();

            $submittedRecaptchaForm = $form;
        });
    });
});

/**
 * Recaptcha callback function - trigger submission of the form with the additional parameter to allow the submission.#
 *
 * @param {string} token The recaptcha token.
 */
window.onRecaptchaSubmit = (token) => {
    $submittedRecaptchaForm.trigger('submit', [true]);
};
