$(() => {
    const MODAL_CLASS = '.c-2fa-disable-modal';
    const MODAL_PAGE_MASK_CLASS = '.c-2fa-disable-modal__page-mask';
    const OPEN_MODAL_CLASS = '.js-2fa-disable-modal-open';
    const CLOSE_MODAL_CLASS = '.js-2fa-disable-modal-close';

    const closeModal = () => {
        $(MODAL_CLASS)
            .removeClass('is-open');

        $(MODAL_PAGE_MASK_CLASS)
            .removeClass('is-open');
    };

    const openModal = () => {
        $(MODAL_CLASS)
            .addClass('is-open');

        $(MODAL_PAGE_MASK_CLASS)
            .addClass('is-open');
    };

    $(CLOSE_MODAL_CLASS).click((event) => {
        event.stopPropagation();
        closeModal();
    });
    $(OPEN_MODAL_CLASS).click((event) => {
        event.stopPropagation();
        openModal();
    });
});
