/**
 * Simple accordion JS.
 */

import $ from '../../../../assets/js/common/jquery';


const SELECTOR = '.js-simple-accordion';
const TITLE_SELECTOR = `${SELECTOR}__title`;
const OPEN_CLASS = 'is-open';

$(() => {
    const accordions = document.querySelectorAll(SELECTOR);

    accordions.forEach((accordion) => {
        const title = accordion.querySelector(TITLE_SELECTOR);

        title.onclick = () => {
            accordion.classList.toggle(OPEN_CLASS);
        };
    });
});
