const removeFormIcons = document.querySelectorAll('.basket-table__item-remove-icon');
const removeAllFormIcon = document.querySelector('.basket-table__footer-remove-all-link');

if (removeFormIcons.length > 0) {
    removeFormIcons.forEach((el) => {
        el.addEventListener('click', submitRemoveSingleItemForm);
    })
}

if (removeAllFormIcon) {
    removeAllFormIcon.addEventListener('click', submitRemoveAllItemsForm);
}

function submitRemoveSingleItemForm(event) {
    event.preventDefault();
    if (confirm('Are you sure you want to remove product from basket')) {
        event.target.parentElement.parentElement.submit();
    }
}

function submitRemoveAllItemsForm(event) {
    event.preventDefault();
    if (confirm('Are you sure you want to remove all items from your basket?')) {
        event.target.parentElement.parentElement.submit();
    }
}



