/**
 * Functionality to control visibility of elements based on the value of a select.
 */

const SELECTOR = '.c-visibility-toggle';

const DATA_TARGET_MAP = 'targetMap';
const DATA_ORIGINAL_DISPLAY = 'originalDisplay';

$(() => {
    const selects = document.querySelectorAll(SELECTOR);

    selects.forEach((select) => {
        const targetMap = JSON.parse(select.dataset[DATA_TARGET_MAP]);
        const targets = [];

        for (const value in targetMap) {
            if (targetMap.hasOwnProperty(value)) {
                const targetSelector = targetMap[value];
                const targetsForValue = document.querySelectorAll(targetSelector);

                targetsForValue.forEach((targetForValue) => {
                    targetForValue.dataset[DATA_ORIGINAL_DISPLAY] = targetForValue.style.display;
                    targets.push(targetForValue);
                });
            }
        }

        /**
         * Make only the elements that should be visible, visible and hide everything else.
         */
        const makeRelevantElementsVisible = function () {
            const makeVisibleSelector = targetMap[select.value];

            targets.forEach((target) => {
                target.style.display = 'none';
            });

            document.querySelectorAll(makeVisibleSelector).forEach((target) => {
                target.style.display = target.dataset[DATA_ORIGINAL_DISPLAY];
            });
        };

        select.addEventListener('change', (event) => {
            makeRelevantElementsVisible();
        });

        makeRelevantElementsVisible();
    });
});
