/**
 * ShoppingVoucher amount selector.
 */
import $ from '../../../../assets/js/common/jquery';

import ShoppingVoucherAmountSelectorModel from './shopping-voucher-amount-selector-model';
import ShoppingVoucherAmountSelectorView from './shopping-voucher-amount-selector-view';

$(() => {
    const ELEMENT_CLASS = 'c-shopping-voucher-amount-selector';
    const $amountSelectors = $(`.${ELEMENT_CLASS}`);

    $amountSelectors.each((index, element) => {
        const $element = $(element);

        const DATA_KEYS = {
            denominations: 'denominations',
            discountPercentage: 'discount-percentage',
            messages: 'messages',
        };

        const denominations = $element.data(DATA_KEYS.denominations);
        const discountPercentage = $element.data(DATA_KEYS.discountPercentage);
        const messages = $element.data(DATA_KEYS.messages);

        const model = new ShoppingVoucherAmountSelectorModel(
            denominations,
            parseFloat(discountPercentage),
        );

        const view = new ShoppingVoucherAmountSelectorView($element, ELEMENT_CLASS, model, messages);
    });
});
