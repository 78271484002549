document.addEventListener('DOMContentLoaded', () => {
   let removeCardButtons = document.querySelectorAll('.c-user-saved-payment-card__remove-default-card')

    removeCardButtons.forEach(function (button) {
        button?.addEventListener('click', (event) => {
            event.preventDefault();

            let confirmMessage = confirm('Are you sure you wish to remove this card?');

            if (confirmMessage) {
                let formId = button.getAttribute('data-remove-card-button');
                let findForm = document.querySelector(`#form-${formId}`);
                findForm.submit();
            }
        })
    });
});
