/**
 * Reloadable card amount selector view.
 */
export default class ReloadableCardAmountSelectorView {
    /**
     * Constructor.
     *
     * @param {HTMLElement} element The main element.
     * @param {string} mainClass The main CSS class.
     * @param {EvoucherAmountSelectorModel} model The main CSS class.
     * @param {Object} messages A hash of messages.
     */
    constructor(element, mainClass, model, messages) {
        this.element = element;
        this.mainClass = mainClass;
        this.model = model;
        this.messages = messages;

        this.generateConfig();
        this.findSubElements();

        this.element.classList.remove(this.config.classes.noJs);

        this.readDenominationFromInputAndSet();
        this.readMemorableDateFromInputAndSet();
        this.render();
        this.setupEventHandlers();
    }

    /**
     * Generate some configuration data.
     */
    generateConfig() {
        const prefix = this.mainClass;

        this.config = {
            currencySymbol: '£',
            classes: {
                buyNewNav: `${prefix}__nav-link--buy-new`,
                cardIdInput: `${prefix}__card-id-input`,
                denominationInput: `${prefix}__denomination-input`,
                form: `${prefix}__form`,
                formActive: `${prefix}__form--active`,
                mainErrorMessage: `${prefix}__main-error-message`,
                memorableDateSection: `${prefix}__memorable-date-section`,
                memorableDateSectionActive: `${prefix}__memorable-date-section--active`,
                memorableDateInput: `${prefix}__memorable-date-input`,
                navLinkActive: `${prefix}__nav-link--active`,
                noJs: `${prefix}--no-js`,
                selectAnotherCardLink: `${prefix}__select-another-card-link`,
                selectAnotherCardLinkActive: `${prefix}__select-another-card-link--active`,
                submit: `${prefix}__submit`,
                submitDisabled: `${prefix}__submit--disabled`,
                summaryRrp: 'c-product-widget-costs-summary__value--rrp',
                summaryTotal: 'c-product-widget-costs-summary__value--total',
                summaryYouSave: 'c-product-widget-costs-summary__value--you-save',
                topUpCard: `${prefix}__top-up-card`,
                topUpCardNumberHeading: `${prefix}__top-up-card-number-heading`,
                topUpCardNumberHeadingActive: `${prefix}__top-up-card-number-heading--active`,
                topUpNav: `${prefix}__nav-link--top-up`,
                topUpSection: `${prefix}__top-up-section`,
                topUpSectionActive: `${prefix}__top-up-section--active`,
            },
        };
    }

    /**
     * Store references to elements for later.
     */
    findSubElements() {
        this.topUpNav = this.element.querySelector(`.${this.config.classes.topUpNav}`);
        this.buyNewNav = this.element.querySelector(`.${this.config.classes.buyNewNav}`);
        this.topUpSection = this.element.querySelector(`.${this.config.classes.topUpSection}`);
        this.topUpCardNumberHeading = this.element.querySelector(`.${this.config.classes.topUpCardNumberHeading}`);

        this.topUpCards = this.element.querySelectorAll(`.${this.config.classes.topUpCard}`);

        this.mainErrorMessage = this.element.querySelector(`.${this.config.classes.mainErrorMessage}`);

        this.summaryTotal = this.element.querySelector(`.${this.config.classes.summaryTotal}`);
        this.summaryRrp = this.element.querySelector(`.${this.config.classes.summaryRrp}`);
        this.summaryYouSave = this.element.querySelector(`.${this.config.classes.summaryYouSave}`);

        this.form = this.element.querySelector(`.${this.config.classes.form}`);
        this.memorableDateSection = this.element.querySelector(`.${this.config.classes.memorableDateSection}`);
        this.selectAnotherCardLink = this.element.querySelector(`.${this.config.classes.selectAnotherCardLink}`);
        this.memorableDateSection = this.element.querySelector(`.${this.config.classes.memorableDateSection}`);
        this.denominationInput = this.element.querySelector(`.${this.config.classes.denominationInput}`);
        this.memorableDateInput = this.element.querySelector(`.${this.config.classes.memorableDateInput}`);
        this.cardIdInput = this.element.querySelector(`.${this.config.classes.cardIdInput}`);
        this.submit = this.element.querySelector(`.${this.config.classes.submit}`);
    }

    /**
     * Setup the event handlers.
     */
    setupEventHandlers() {
        if (this.model.hasTopUpOption()) {
            this.topUpNav.addEventListener('click', this.onTopUpNavClick.bind(this));
        }

        if (this.model.hasBuyNewCardOption()) {
            this.buyNewNav.addEventListener('click', this.onBuyNewNavClick.bind(this));
        }

        this.selectAnotherCardLink.addEventListener('click', this.onSelectAnotherCardClick.bind(this));

        this.topUpCards.forEach((card) => {
            card.addEventListener('click', this.onTopUpCardClick.bind(this));
        });

        this.form.addEventListener('submit', this.onFormSubmit.bind(this));
        this.denominationInput.addEventListener('keyup', this.denominationInputChange.bind(this));
        this.memorableDateInput.addEventListener('mousemove', this.onMemorableDateChange.bind(this));
        this.memorableDateInput.addEventListener('blur', this.onMemorableDateChange.bind(this));
        this.memorableDateInput.addEventListener('keyup', this.onMemorableDateChange.bind(this));
        this.memorableDateInput.addEventListener('change', this.onMemorableDateChange.bind(this));
    }

    /**
     * Set the denomination to a specific number.
     *
     * @param {number} denomination The denomination to set.
     */
    setDenomination(denomination) {
        this.model.denomination = denomination;

        this.renderDenominationChangeElements();
    }

    /**
     * Set the memorable date.
     *
     * @param {string} date The date.
     */
    setMemorableDate(date) {
        this.model.memorableDate = date;

        this.renderMemorableDateChangeElements();
    }

    /**
     * Read the value from the denomination input and set the amount accordingly.
     */
    readDenominationFromInputAndSet() {
        const { value } = this.denominationInput;

        if (!/^\d*$/.test(value)) {
            this.setDenomination(0);
            this.displayError(this.messages.amountMustBeInteger);

            return;
        }

        this.hideError();

        let intValue = parseInt(value, 10);

        // A value of '' will result in NaN here - set denomination to zero in that case.
        if (Number.isNaN(intValue)) {
            intValue = 0;
        }

        if (this.model.canAmountBePurchased(intValue)) {
            this.setDenomination(intValue);
        } else {
            this.setDenomination(0);

            if (value.length > 0 && intValue !== 0) {
                this.displayError(this.messages.amountMustBeBetweenMinAndMax);
            }
        }
    }

    /**
     * Read the date from the memorable date input and set it on the model.
     */
    readMemorableDateFromInputAndSet() {
        const { value } = this.memorableDateInput;

        if (!/^\d{2}-\d{2}-\d{4}$/.test(value)) {
            this.setMemorableDate(null);
            if (value.length > 0) {
                this.displayError(this.messages.invalidMemorableDate);
            }

            return;
        }

        this.hideError();
        this.setMemorableDate(value);
    }

    /**
     * Update the costs and savings values to represent the current amount.
     */
    renderCostsAndSavings() {
        const total = this.model.calculateDiscountedTotal();
        const rrp = this.model.calculateRrp();
        const saving = this.model.calculateSaving();

        this.summaryTotal.innerHTML = this.formatCurrency(total);
        this.summaryRrp.innerHTML = this.formatCurrency(rrp);

        const youSave = `${this.formatCurrency(saving)} (${this.model.discountPercentage}%)`;
        this.summaryYouSave.innerHTML = youSave;
    }

    /**
     * Enable or disable the submit button based on the current selection.
     */
    renderSubmitButton() {
        if (this.model.canBeSubmitted()) {
            this.submit.classList.remove(this.config.classes.submitDisabled);
        } else {
            this.submit.classList.add(this.config.classes.submitDisabled);
        }
    }

    /**
     * Render the navigation.
     */
    renderNav() {
        if (this.model.hasTopUpOption()) {
            if (this.model.buyingNewCard) {
                this.topUpNav.classList.remove(this.config.classes.navLinkActive);
            } else {
                this.topUpNav.classList.add(this.config.classes.navLinkActive);
            }
        }

        if (this.model.hasBuyNewCardOption()) {
            if (this.model.buyingNewCard) {
                this.buyNewNav.classList.add(this.config.classes.navLinkActive);
            } else {
                this.buyNewNav.classList.remove(this.config.classes.navLinkActive);
            }
        }
    }

    /**
     * Render the card list.
     */
    renderCardList() {
        if (this.model.topUpCard === null && !this.model.buyingNewCard) {
            this.topUpSection.classList.add(this.config.classes.topUpSectionActive);
        } else {
            this.topUpSection.classList.remove(this.config.classes.topUpSectionActive);
        }
    }

    /**
     * Render the main form.
     */
    renderForm() {
        if (this.model.buyingNewCard || this.model.topUpCard !== null) {
            this.form.classList.add(this.config.classes.formActive);

            if (this.model.buyingNewCard) {
                this.selectAnotherCardLink.classList.remove(this.config.classes.selectAnotherCardLinkActive);
                this.memorableDateSection.classList.add(this.config.classes.memorableDateSectionActive);
                this.topUpCardNumberHeading.classList.remove(this.config.classes.topUpCardNumberHeadingActive);
            } else {
                this.selectAnotherCardLink.classList.add(this.config.classes.selectAnotherCardLinkActive);
                this.memorableDateSection.classList.remove(this.config.classes.memorableDateSectionActive);
                this.topUpCardNumberHeading.classList.add(this.config.classes.topUpCardNumberHeadingActive);
                this.topUpCardNumberHeading.innerHTML = `${this.messages.topUp} ${this.model.topUpCard.cardNumber}`;
            }
        } else {
            this.form.classList.remove(this.config.classes.formActive);
        }

        this.cardIdInput.value = this.model.topUpCard !== null ? this.model.topUpCard.cardId : null;
    }

    /**
     * Render the whole UI.
     */
    render() {
        this.renderSubmitButton();
        this.renderNav();
        this.renderCardList();
        this.renderForm();
        this.renderCostsAndSavings();
    }

    /**
     * Render the elements that change when denomination changes.
     */
    renderDenominationChangeElements() {
        this.renderCostsAndSavings();
        this.renderSubmitButton();
    }

    /**
     * Render the elements that change when memorable date changes.
     */
    renderMemorableDateChangeElements() {
        this.renderSubmitButton();
    }

    /**
     * Display a currency amount.
     *
     * @param {number} amount The amount to display in pence.
     *
     * @return {string} A formatted currency amount.
     */
    formatCurrency(amount) {
        return this.config.currencySymbol + (amount / 100).toFixed(2);
    }

    /**
     * Display an error message.
     *
     * @param {string} message The message to display.
     */
    displayError(message) {
        this.mainErrorMessage.innerHTML = message;
    }

    /**
     * Hide the error message.
     */
    hideError() {
        this.mainErrorMessage.innerHTML = '';
    }

    /**
     * Handler for change event on the denomination input.
     */
    denominationInputChange() {
        this.readDenominationFromInputAndSet();
    }

    /**
     * Handler for change event on the memorable date input.
     */
    onMemorableDateChange() {
        this.readMemorableDateFromInputAndSet();
    }

    /**
     * Handler for form submission.
     *
     * @param {event} e The submit event.
     */
    onFormSubmit(e) {
        // Refresh the value in case the form was submitted by pressing enter in the input (i.e. not clicking).
        this.readDenominationFromInputAndSet();

        if (!this.model.canBeSubmitted()) {
            e.preventDefault();
        }
    }

    /**
     * Click handler for navigation link to top up an existing card.
     *
     * @param {event} e The click event.
     */
    onTopUpNavClick(e) {
        e.preventDefault();

        this.model.buyingNewCard = false;
        this.model.topUpCard = null;

        this.render();
    }

    /**
     * Click handler for navigation link to buy a new card.
     *
     * @param {event} e The click event.
     */
    onBuyNewNavClick(e) {
        e.preventDefault();

        this.model.buyingNewCard = true;
        this.model.topUpCard = null;

        this.render();
    }

    /**
     * Click handler for top up cards.
     *
     * @param {event} e The click event.
     */
    onTopUpCardClick(e) {
        const { cardNumber } = e.currentTarget.dataset;

        // If there is no card number, just go to the link's href - it will go somewhere the user can add card details.
        if (cardNumber.length === 0) {
            return;
        }

        e.preventDefault();

        const { cardId } = e.currentTarget.dataset;

        this.model.buyingNewCard = false;

        this.model.topUpCard = {
            cardId,
            cardNumber,
        };

        this.render();
    }

    /**
     * Click handler for link to go back to select another card to top-up.
     *
     * @param {event} e The click event.
     */
    onSelectAnotherCardClick(e) {
        e.preventDefault();

        this.model.buyingNewCard = false;
        this.model.topUpCard = null;

        this.render();
    }
}
