/**
 * Postcode lookup.
 *
 * Functionality is triggered by clicking on elements with a "postcode-lookup" class. The element should also have the
 * following data attributes set:
 *
 * data-url: The URL that will be requested to return address data.
 *
 * data-params: A JSON string representing an object with URL parameters as keys and DOM selectors as values. The keys
 *              specify the URL parameters that should be added to the AJAX request (e.g. "postcode"), the values are
 *              the DOM selectors where the values should be obtained from (e.g. "#postcode").
 *
 * data-outputs: A JSON string representing an object with fields as keys and DOM selectors as values. The keys specify
 *               the properties of the AJAX response (e.g. "county"), the values specify the DOM selectors of the inputs
 *               that should be updated with the values from the response (e.g. "#county").
 */

import $ from '../../../../assets/js/common/jquery';


const SELECTOR = '.postcode-lookup';

const DATA_URL = 'url';
const DATA_PARAMS = 'params';
const DATA_OUTPUTS = 'outputs';

$(() => {
    const $buttons = $(SELECTOR);

    $buttons.each((index, element) => {
        const $element = $(element);

        $element.click((event) => {
            event.preventDefault();

            const url = $element.data(DATA_URL);

            // Map AJAX parameters to selectors: key is the URL param, selector is the source of the parameter value.
            const params = $element.data(DATA_PARAMS);

            // Map fields to selectors: the key is a property of the AJAX response, selector is the element to update.
            const outputs = $element.data(DATA_OUTPUTS);

            // Ensure we have a URL, a URL parameter map and an output map, otherwise we cannot do anything.
            if (url === undefined || params !== Object(params) || outputs !== Object(outputs)) {
                return false;
            }

            // Get the parameter values we'll need so we can make an AJAX request.
            const paramValues = {};
            for (const urlParam in params) {
                const value = $(params[urlParam]).val();

                paramValues[urlParam] = value;
            }

            if (!paramValues.postcode) {
                alert('Please provide a postcode.');
            } else {
                $.ajax(
                    {
                        url,
                        type: 'GET',
                        data: paramValues,
                        success: (responseData) => {
                            if (
                                responseData.hasOwnProperty('addresses')
                                && Array.isArray(responseData.addresses)
                                && responseData.addresses[0] !== undefined
                            ) {
                                /*
                                 * Response will contain an array of addresses - we only care about the first one as our
                                 * basic UI doesn't allow the user to choose one of the options, it just defaults to the
                                 * first returned.
                                 */
                                const addressData = responseData.addresses[0];

                                // Loop over the fields - if we have an output selector for that field, populate it.
                                for (const field in addressData) {
                                    if (outputs[field] !== undefined) {
                                        $(outputs[field]).val(addressData[field]);
                                    }
                                }
                            }
                        },
                        error: () => {
                            alert('There was a problem: please complete the address manually.');
                        },
                    },
                );
            }
        });
    });
});
