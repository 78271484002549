/**
 * Model to represent a shopping voucher amount selector.
 */
export default class ShoppingVoucherAmountSelectorModel {
    /**
     * Constructor.
     *
     * @param {array} denominations An array of denominations that are available.
     * @param {number} discountPercentage The discount percentage.
     */
    constructor(denominations, discountPercentage) {
        this.denominations = denominations;
        this.discountPercentage = discountPercentage;

        this.activeDenomination = null;
        this.quantity = 0;

        this.mobileUiVisible = false;
    }

    /**
     * Determine if this model has at least one denomination set.
     *
     * @return {bool} True if there is at least one denomination.
     */
    hasDenominations() {
        return this.denominations.length > 0;
    }

    /**
     * Calculate the total cost (with discount applied) of a given number of vouchers.
     *
     * @return {number} The discounted total in pence.
     */
    calculateDiscountedTotal() {
        return Math.round(
            this.activeDenomination * 100 * this.quantity / 100 * (100 - this.discountPercentage),
        );
    }

    /**
     * Calculate the Recommended Retail Price of a given number of vouchers.
     *
     * @return {number} The RRP in pence.
     */
    calculateRrp() {
        return (this.quantity * this.activeDenomination * 100);
    }

    /**
     * Determine if the quantity can be decremented.
     *
     * @return {bool} True if the quantity can be decremented.
     */
    canQuantityBeDecremented() {
        return this.quantity > 0;
    }

    /**
     * Calculate the saving (RRP - discounted total).
     *
     * @return {number} The saving in pence.
     */
    calculateSaving() {
        // Call Math.abs as sometimes we get -0 as the value when dealing with non-discounted vouchers.
        return Math.abs(this.calculateRrp() - this.calculateDiscountedTotal());
    }

    /**
     * Determine if the active denomination and quantity can be submitted.
     *
     * @return {bool} True if the denomination/quantity combination can be submitted.
     */
    canBeSubmitted() {
        return this.activeDenomination !== null && this.quantity > 0;
    }
}
