/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

// import './base/toggle';

import './website/jquery-1.12.3.min';
import './website/jquery-3.6.0.min';
import './website/jquery-ui-1.13.3.min';
import './website/select2-4.0.3.min';

import './legacy/legacy';
import './common/polyfills';
import './common/modernizr-custom';
import './common/date-picker';
import './common/hide-show-password';
import './common/external-link-icons';

import '../../library/component/blocks/additional-info-accordion/additional-info-accordion'
import '../../library/component/blocks/autocomplete/autocomplete'
import '../../library/component/blocks/product-list-sort/product-list-sort'
import '../../library/component/blocks/checkout-payment-details-form/checkout-payment-details-form';
import '../../library/component/blocks/checkout-address-form/checkout-address-form';
import '../../library/component/blocks/copyable-input/copyable-input';
import '../../library/component/blocks/copyable-container/copyable-container';
import '../../library/component/blocks/e-wallet-table/e-wallet-table';
import '../../library/component/blocks/e-wallet-modal/e-wallet-modal';
import '../../library/component/blocks/evoucher-amount-selector/evoucher-amount-selector';
import '../../library/component/blocks/onboarding-popup/onboarding-popup';
import '../../library/component/blocks/pill-nav-menu/pill-nav-menu';
import '../../library/component/blocks/postcode-lookup/postcode-lookup';
import '../../library/component/blocks/postcode-lookup-multi-address/postcode-lookup-multi-address';
import '../../library/component/blocks/product-widget-container/product-widget-container';
import '../../library/component/blocks/recaptcha-button/recaptcha-button';
import '../../library/component/blocks/reloadable-card-amount-selector/reloadable-card-amount-selector';
import '../../library/component/blocks/shopping-voucher-amount-selector/shopping-voucher-amount-selector';
import '../../library/component/blocks/simple-accordion/simple-accordion';
import '../../library/component/blocks/visibility-toggle/visibility-toggle';
import '../../library/component/blocks/faq-list/faq-list';
import '../../library/component/blocks/2fa-disable-modal/2fa-disable-modal';
import '../../library/component/blocks/product-carousel/product-carousel';
import '../../library/component/blocks/payment-cards/payment-cards';
import '../../library/component/blocks/order-history-detail/order-history';
import '../../library/component/blocks/product-footer/product-footer';
import '../../library/component/blocks/sso-user-update/sso-user-update';
import '../../library/component/blocks/hot-offers/hot-offers';
import '../../library/component/blocks/dashboard-carousel/dashboard-carousel';
import '../../library/component/blocks/basket-table/basket-table';
import '../../library/component/blocks/user-saved-payment-cards/user-saved-payment-card';
import '../../library/component/blocks/favourites-bar/favourites-bar';

/* Import app scss file for webpack compiling */
import '../css/app.scss';
