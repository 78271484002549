import MicroModal from 'micromodal';

MicroModal.init();

const form = document.querySelector(".sso-user-update-modal form");
const submitButton = document.querySelector('.micromodal__btn-submit');
const modalFooter = document.querySelector('.micromodal__footer');

async function submitForm(formData) {
    const response = await fetch(form.action, {method: "POST", body: formData});
    const data = await response.json();

    removeErrors();

    if (!response.ok) {
        handleErrors(data);
    } else {
        handleSuccessfulResponse(data);
    }
}

function logSubmit(event) {
    event.preventDefault();
    const formData = new FormData(form);
    submitForm(formData);
}

function handleErrors(data) {
    Object.entries(data.errors).forEach(([key, value]) => {
        createError(
            value,
            `${form.name}_${key}`,
            `${key}-form-error`
        );
    });
}

function handleSuccessfulResponse(data) {
    removeFormAndButton();
    createRetailerLink(data.linkOutUrl);
    updateMicroModalHeader();
}

/**
 * Remove the form and footer buttons
 */
function removeFormAndButton() {
    form.remove();
    modalFooter.querySelectorAll('button').forEach((button) => button.remove());
}

/**
 * Creates the retailer link and appends to micro modal footer
 * @param link
 */
function createRetailerLink(link) {
    const divTag = document.createElement('div');
    divTag.classList.add('sso-user-update-modal__visit-retailer');
    divTag.innerHTML = createLink(link);

    modalFooter.append(divTag);
}

/**
 * Creates the retailer hyperlink and svg
 * @param link
 * @returns {string}
 */
function createLink(link) {
    return `
        <a href="${link}" class="micromodal__btn o-button sso-user-update-modal__visit-retailer-link" aria-label="Visit retailer">
            Visit Retailer
            <svg class="sso-user-update-modal__visit-retailer-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_9_1932)">
                    <path d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_9_1932">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </a>
    `;
}

/**
 * Update the micro modal header
 */
function updateMicroModalHeader() {
    const microModalHeader = document.querySelector('.micromodal__header');
    const microModalHeaderTitle = document.querySelector('.micromodal__title');
    const contactDetailsParagraph = document.createElement('p');
    const contactDetailsSavedText = 'Your contact details have been saved.';

    microModalHeaderTitle.textContent = 'Thanks!';

    contactDetailsParagraph.classList.add('sso-user-update-modal__contact-details-saved');
    contactDetailsParagraph.textContent = contactDetailsSavedText;

    microModalHeader.append(contactDetailsParagraph);
}

/**
 * Creates error paragraph when an error is detected
 * @param errorMessage
 * @param input
 * @param identifier
 */
function createError(errorMessage, input, identifier) {
    let parentElement = document.querySelector(`#${input}`).parentElement;
    let pTag = document.createElement('p');
    pTag.classList.add('o-input--error', 'sso-update-error');
    pTag.id = identifier;
    pTag.textContent = errorMessage;

    if (!document.querySelector(`#${identifier}`)) {
        parentElement.appendChild(pTag);
    }
}

/**
 * Removes any errors in the modal
 */
function removeErrors() {
    const errorIds = document.querySelectorAll('.sso-update-error');
    errorIds?.forEach((key) => key.remove());
}

function enableSaveButton() {
    let shouldShow = true;

    form.querySelectorAll('input[required]').forEach((input) => {
       shouldShow = input.value !== '';
    });

    submitButton.disabled = !shouldShow;
}

if (form) {
    submitButton?.addEventListener('click', logSubmit);
    form?.addEventListener('submit', logSubmit);

    document.querySelectorAll('input[required]').forEach((input) => {
        input.addEventListener('change', enableSaveButton);
    });
}
