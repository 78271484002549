/**
 * Model to represent an reloadable card amount selector.
 */
export default class ReloadableCardAmountSelectorModel {
    /**
     * Constructor.
     *
     * @param {number} minimumAmount The minimum amount that can be added (in pounds).
     * @param {number} maximumAmount The maximum amount that can be added (in pounds).
     * @param {number} discountPercentage The discount percentage.
     * @param {array} topUpCards The cards that can be topped-up.
     * @param {bool} canBuyNewCard True if a new card can be bought.
     */
    constructor(minimumAmount, maximumAmount, discountPercentage, topUpCards, canBuyNewCard) {
        this.minimumAmount = minimumAmount;
        this.maximumAmount = maximumAmount;
        this.discountPercentage = discountPercentage;
        this.topUpCards = topUpCards;
        this.canBuyNewCard = canBuyNewCard;

        this.denomination = 0;
        this.memorableDate = null;
        this.topUpCard = null;
        this.buyingNewCard = this.hasTopUpOption() ? false : this.hasBuyNewCardOption();

        this.mobileUiVisible = false;
    }

    /**
     * Calculate the total cost (with discount applied) of a given number of vouchers.
     *
     * @return {number} The discounted total in pence.
     */
    calculateDiscountedTotal() {
        return Math.round(this.denomination * (100 - this.discountPercentage));
    }

    /**
     * Calculate the Recommended Retail Price of a given number of vouchers.
     *
     * @return {number} The RRP in pence.
     */
    calculateRrp() {
        return this.denomination * 100;
    }

    /**
     * Calculate the saving (RRP - discounted total).
     *
     * @return {number} The saving in pence.
     */
    calculateSaving() {
        // Call Math.abs as sometimes we get -0.
        return Math.abs(this.calculateRrp() - this.calculateDiscountedTotal());
    }

    /**
     * Determine if the amount can be set to a particular value.
     *
     * @param {Number} amount The amount.
     *
     * @return {bool} True if the amount can be set to the value.
     */
    canAmountBePurchased(amount) {
        return amount >= this.minimumAmount && amount <= this.maximumAmount;
    }

    /**
     * Determine if the amount can be submitted.
     *
     * @return {bool} True if the amount can be submitted.
     */
    canBeSubmitted() {
        return this.canAmountBePurchased(this.denomination) && (this.memorableDate !== null || !this.buyingNewCard);
    }

    /**
     * Determine if top-ups can occur.
     *
     * @return {bool} True if top-ups can occur.
     */
    hasTopUpOption() {
        return this.topUpCards.length > 0;
    }

    /**
     * Determine if new cards can be bought.
     *
     * @return {bool} True if new cards can be bought.
     */
    hasBuyNewCardOption() {
        return this.canBuyNewCard;
    }
}
