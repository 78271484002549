import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
  const allCarousels = document.querySelectorAll('.js-dashboard-carousel');
  const thumbnailsSizeLarge = 96;
  const thumbnailsSize = 76;

  for (let i = 0; i < allCarousels.length; i++) {
    const carouselContainer = allCarousels[i];
    const carousel = carouselContainer.querySelector('.js-dashboard-carousel-main');
    const pagination = carouselContainer.querySelector('.js-dashboard-carousel-pagination');

    const main = new Splide(carousel, {
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: false,
      breakpoints: {
        767: {
          pagination: true,
        },
      },
    });

    let thumbnails = new Splide(pagination, {
      fixedWidth: thumbnailsSizeLarge,
      fixedHeight: thumbnailsSizeLarge + 8,
      gap: 0,
      rewind: true,
      pagination: false,
      isNavigation: true,
      padding: 16,  //for dd
      autoplay: 'pause',
      breakpoints: {
        767: {
          fixedWidth: thumbnailsSize,
          fixedHeight: thumbnailsSize + 8, // 60px height + space for dd
        },
      },
    });

    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();
  }
});
