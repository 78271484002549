/**
 * Cashback accordion.
 *
 * Displays an initially hidden section of content which is displayed by clicking the "Show More" hyperlink,
 * and is hidden by clicking the "Hide" hyperlink.
 */

import $ from '../../../../assets/js/common/jquery';

$(() => {
    const ADDITIONAL_INFO_ACCORDION_PANEL = '.c-additional-info-accordion__body';
    const ADDITIONAL_INFO_ACCORDION_LINK = '.c-additional-info-accordion__link';

    $(ADDITIONAL_INFO_ACCORDION_LINK).click(function (e) {
        // Variables to store the reference to the elements
        const additionalInfoAccordionPanel = $(this).parent().children(ADDITIONAL_INFO_ACCORDION_PANEL);
        const additionalInfoAccordionTextShow = $(this).data('show');
        const additionalInfoAccordionTextHide = $(this).data('hide');

        // Prevent the hyperlink from firing
        e.preventDefault();
        // Toggle the visibility of the panel
        additionalInfoAccordionPanel.toggle();

        // Switch the hyperlink text depending on
        // whether the panel is displayed or hidden
        if (additionalInfoAccordionPanel.is(':visible')) {
            $(this).toggleClass('is-active')
            $(this).text(additionalInfoAccordionTextHide);
        } else {
            $(this).text(additionalInfoAccordionTextShow);
        }
    });
});
