/**
 * Reloadable card amount selector.
 */
import $ from '../../../../assets/js/common/jquery';

import ProductWidgetContainerView from './product-widget-container-view';

$(() => {
    const ELEMENT_CLASS = 'c-product-widget-container';
    const elements = document.querySelectorAll(`.${ELEMENT_CLASS}`);

    elements.forEach((element) => {
        const view = new ProductWidgetContainerView(element, ELEMENT_CLASS);
    });
});
