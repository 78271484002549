/**
 * Copyable container JS.
 */

import $ from '../../../../assets/js/common/jquery';


const CONTAINER_SELECTOR = '.js-copyable-container';
const MESSAGE_TIMEOUT_MS = 2000;

$(() => {
    const containers = document.querySelectorAll(CONTAINER_SELECTOR);

    containers.forEach((container) => {
        // Add a copy button next to each element.
        const icon = document.createElement('span');
        icon.textContent = 'Copy';
        icon.className = 'c-copyable-container__icon';

        container.onclick = () => {
            const textToCopy = container.querySelectorAll('.c-copyable-container__inner')[0].textContent.trim();
            if (navigator.clipboard !== undefined) { // Chrome
                navigator.clipboard.writeText(textToCopy);
            } else if (window.clipboardData) { // Internet Explorer
                window.clipboardData.setData('Text', textToCopy);
            }

            icon.classList.add('is-copied');

            // Display a message indicating the copy succeeded, removing the message after a time.
            setTimeout(() => {
                icon.classList.remove('is-copied');
            }, MESSAGE_TIMEOUT_MS);
        };

        container.appendChild(icon); // Add the copy button to the DOM after the input.
    });
});
