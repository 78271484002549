import $ from '../../../../assets/js/common/jquery';


const config = {
    onboardingFormSelector: '.js-onboarding-form',
    lightboxSelector: '#onboarding_lightbox',
};

class onboardingGuide {
    /**
     * Constructor for onboardingGuide
     */
    constructor() {
        this.onboardingForm = $(config.onboardingFormSelector);
        this.lightbox = $(config.lightboxSelector);

        this.bindLightbox();
    }

    /**
     * Listener for onboarding popup.
     *
     * @return {void}
     */
    bindLightbox() {
        const onboardingLightBox = document.querySelector('#onboarding_lightbox');

        if (this.lightbox.length) {
            this.lightbox.modal('show');

            onboardingLightBox.ariaHidden = 'false';

            this.lightbox.carousel('pause');


            this.lightbox.on('slid.bs.carousel', '', function () {
                const $this = $(this);
                // Bootstrap stuff.
                if ($this.find('.carousel-inner .item:first').hasClass('active')) {
                    $this.find('.left').hide();
                    $this.find('.right').show();
                    $this.find('.js-onboarding-status').addClass('u-hide');
                    $this.find('.right span:first').text('Start');
                    $this.find('.right').attr('data-dismiss', 'false');
                } else if ($this.find('.carousel-inner .item:last').hasClass('active')) {
                    $this.find('.js-onboarding-status').removeClass('u-hide');
                    $this.find('.right').attr('data-dismiss', 'modal');
                    $this.find('.right span:first').text('Done');
                    $this.find('.left').show();
                } else {
                    $this.find('.left').show();
                    $this.find('.right').show();
                    $this.find('.js-onboarding-status').addClass('u-hide');
                    $this.find('.right span:first').text('Next');
                    $this.find('.right').attr('data-dismiss', 'false');
                }
            });

            // When we close Onboarding popup (anyhow) we are doing ajax request. To submit form and update session.
            this.lightbox.on('hide.bs.modal', () => {

                onboardingLightBox.ariaHidden = 'true';
                /**
                 * This removes modal entirely from the markup when we close modal.
                 * As there is an issue wit multiple forms being on the same page.
                 */
                $(this).data('bs.modal', null);

                // Sending form to an Ajax controller.
                this.sendOnboardingForm();
            });
        }
    }

    /**
     * Ajax POST request for onboarding form.
     *
     * @return {void}
     */
    sendOnboardingForm() {
        $.ajax({
            type: 'POST',
            url: this.onboardingForm.attr('action'),
            data: this.onboardingForm.serialize(),
            success: (data) => {
                // Add code here, if we need to do anything on success.
            },
        });
    }
}

$(() => {
    // Initiate onboarding class
    new onboardingGuide();
});
