/**
 * Checkout address form view.
 */

import $ from '../../../../assets/js/common/jquery';


export default class CheckoutAddressFormView {
    /**
     * Constructor.
     *
     * @param {jQuery} $element The main element.
     * @param {string} mainClass The main CSS class.
     * @param {EvoucherAmountSelectorModel} model The model.
     */
    constructor($element, mainClass, model) {
        this.$element = $element;
        this.mainClass = mainClass;
        this.model = model;

        this.generateConfig();
        this.findSubElements();
        this.setUpEventHandlers();

        this.render();
    }

    /**
     * Generate some configuration data.
     */
    generateConfig() {
        this.config = {
            classes: {
                nonJs: `${this.mainClass}--no-js`,
                hide: 'u-hide',
                useSavedContactInformationFields: `${this.mainClass}__use-saved-contact-information-fields`,
                addressLookupFieldsBilling: `${this.mainClass}__address-lookup-fields--billing`,
                addressLookupFieldsDelivery: `${this.mainClass}__address-lookup-fields--delivery`,
                deliverySameAsBillingAddressFields: `${this.mainClass}__delivery-same-as-billing-address-fields`,
                manualAddressFieldsBilling: `${this.mainClass}__manual-address-fields--billing`,
                manualAddressFieldsDelivery: `${this.mainClass}__manual-address-fields--delivery`,
                nameFieldsBilling: `${this.mainClass}__name-fields--billing`,
                nameFieldsDelivery: `${this.mainClass}__name-fields--delivery`,
                useAddressLookupLinkBilling: `${this.mainClass}__use-address-lookup-link--billing`,
                useAddressLookupLinkDelivery: `${this.mainClass}__use-address-lookup-link--delivery`,
                useManualAddressLinkBilling: `${this.mainClass}__use-manual-address-link--billing`,
                useManualAddressLinkDelivery: `${this.mainClass}__use-manual-address-link--delivery`,
            },
        };
    }

    /**
     * Store references to elements for later.
     */
    findSubElements() {
        this.$useSavedContactInformationInputs = this.$element.find(
            `.${this.config.classes.useSavedContactInformationFields} input`,
        );

        this.$deliverySameAsBillingAddressInputs = this.$element.find(
            `.${this.config.classes.deliverySameAsBillingAddressFields} input`,
        );

        this.$addressLookupFieldsBilling = this.$element.find(`.${this.config.classes.addressLookupFieldsBilling}`);
        this.$addressLookupFieldsDelivery = this.$element.find(`.${this.config.classes.addressLookupFieldsDelivery}`);

        this.$manualAddressFieldsBilling = this.$element.find(`.${this.config.classes.manualAddressFieldsBilling}`);
        this.$manualAddressFieldsDelivery = this.$element.find(`.${this.config.classes.manualAddressFieldsDelivery}`);

        this.$nameFieldsBilling = this.$element.find(`.${this.config.classes.nameFieldsBilling}`);
        this.$nameFieldsDelivery = this.$element.find(`.${this.config.classes.nameFieldsDelivery}`);

        this.$useAddressLookupLinkBilling = this.$element.find(`.${this.config.classes.useAddressLookupLinkBilling}`);
        this.$useAddressLookupLinkDelivery = this.$element.find(`.${this.config.classes.useAddressLookupLinkDelivery}`);

        this.$useManualAddressLinkBilling = this.$element.find(`.${this.config.classes.useManualAddressLinkBilling}`);
        this.$useManualAddressLinkDelivery = this.$element.find(`.${this.config.classes.useManualAddressLinkDelivery}`);
    }

    /**
     * Render.
     */
    render() {
        this.$element.removeClass(this.config.classes.nonJs);

        this.renderAddressLookupFields();
        this.renderManualAddressFields();
        this.renderNameFields();
        this.renderLinks();
    }

    /**
     * Render the address lookup fields.
     */
    renderAddressLookupFields() {
        // Billing.
        if (this.model.shouldShowAddressLookupFieldsForBilling()) {
            this.show(this.$addressLookupFieldsBilling);
        } else {
            this.hide(this.$addressLookupFieldsBilling);
        }

        // Delivery.
        if (this.model.shouldShowAddressLookupFieldsForDelivery()) {
            this.show(this.$addressLookupFieldsDelivery);
        } else {
            this.hide(this.$addressLookupFieldsDelivery);
        }
    }

    /**
     * Render the manual address fields.
     */
    renderManualAddressFields() {
        // Billing.
        if (this.model.shouldShowManualAddressFieldsForBilling()) {
            this.show(this.$manualAddressFieldsBilling);
        } else {
            this.hide(this.$manualAddressFieldsBilling);
        }

        // Delivery.
        if (this.model.shouldShowManualAddressFieldsForDelivery()) {
            this.show(this.$manualAddressFieldsDelivery);
        } else {
            this.hide(this.$manualAddressFieldsDelivery);
        }
    }

    /**
     * Render the name fields.
     */
    renderNameFields() {
        // Billing.
        if (this.model.shouldShowNameFieldsForBilling()) {
            this.show(this.$nameFieldsBilling);
        } else {
            this.hide(this.$nameFieldsBilling);
        }

        // Delivery.
        if (this.model.shouldShowNameFieldsForDelivery()) {
            this.show(this.$nameFieldsDelivery);
        } else {
            this.hide(this.$nameFieldsDelivery);
        }
    }

    /**
     * Render the links that toggle the address lookup/manual address fields.
     */
    renderLinks() {
        // Billing.
        if (this.model.shouldShowUseAddressLookupLinkForBilling()) {
            this.show(this.$useAddressLookupLinkBilling);
        } else {
            this.hide(this.$useAddressLookupLinkBilling);
        }

        if (this.model.shouldShowUseManualAddressLinkForBilling()) {
            this.show(this.$useManualAddressLinkBilling);
        } else {
            this.hide(this.$useManualAddressLinkBilling);
        }

        // Delivery.
        if (this.model.shouldShowUseAddressLookupLinkForDelivery()) {
            this.show(this.$useAddressLookupLinkDelivery);
        } else {
            this.hide(this.$useAddressLookupLinkDelivery);
        }

        if (this.model.shouldShowUseManualAddressLinkForDelivery()) {
            this.show(this.$useManualAddressLinkDelivery);
        } else {
            this.hide(this.$useManualAddressLinkDelivery);
        }
    }

    /**
     * Setup some event handlers.
     */
    setUpEventHandlers() {
        this.$useSavedContactInformationInputs.change(this.onUseSavedContactInformationChange.bind(this));

        this.$deliverySameAsBillingAddressInputs.change(this.onDeliverySameAsBillingAddressChange.bind(this));

        this.$useAddressLookupLinkBilling.click(this.onUseAddressLookupLinkBillingClick.bind(this));
        this.$useAddressLookupLinkDelivery.click(this.onUseAddressLookupLinkDeliveryClick.bind(this));

        this.$useManualAddressLinkBilling.click(this.onUseManualAddressLinkBillingClick.bind(this));
        this.$useManualAddressLinkDelivery.click(this.onUseManualAddressLinkDeliveryClick.bind(this));
    }

    /**
     * Handler for click on useSavedContactInformationFields.
     *
     * @param {Event} e The event.
     */
    onUseSavedContactInformationChange(e) {
        this.model.setBillingUseSavedContactInformationAddress($(e.target).val() === 'saved');

        this.render();
    }

    /**
     * Handler for change of deliverySameAsBillingAddress radio inputs.
     *
     * @param {Event} e The event.
     */
    onDeliverySameAsBillingAddressChange(e) {
        this.model.setDeliverySameAsBillingAddress($(e.target).val() !== 'new');

        this.render();
    }

    /**
     * Handler for click on useAddressLookupLinkBilling.
     *
     * @param {Event} e The event.
     */
    onUseAddressLookupLinkBillingClick(e) {
        e.preventDefault();

        this.model.setUsingAddressLookupBilling(true);

        this.render();
    }

    /**
     * Handler for click on useAddressLookupLinkBilling.
     *
     * @param {Event} e The event.
     */
    onUseAddressLookupLinkDeliveryClick(e) {
        e.preventDefault();

        this.model.setUsingAddressLookupDelivery(true);

        this.render();
    }

    /**
     * Handler for click on useManualAddressLinkBilling.
     *
     * @param {Event} e The event.
     */
    onUseManualAddressLinkBillingClick(e) {
        e.preventDefault();

        this.model.setUsingAddressLookupBilling(false);

        this.render();
    }

    /**
     * Handler for click on useManualAddressLinkDelivery.
     *
     * @param {Event} e The event.
     */
    onUseManualAddressLinkDeliveryClick(e) {
        e.preventDefault();

        this.model.setUsingAddressLookupDelivery(false);

        this.render();
    }

    /**
     * Hide an element.
     *
     * @param {jQuery} $element An element.
     */
    hide($element) {
        $element.addClass(this.config.classes.hide);
    }

    /**
     * Show an element.
     *
     * @param {jQuery} $element An element.
     */
    show($element) {
        $element.removeClass(this.config.classes.hide);
    }
}
