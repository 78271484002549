/**
 * Checkout payment details form.
 *
 * Handles submission of the card data to Sage Pay.
 */
import $ from '../../../../assets/js/common/jquery';


import CheckoutPaymentDetailsFormView from './checkout-payment-details-form-view';

$(() => {
    const ELEMENT_CLASS = 'c-checkout-payment-details-form';
    const $elements = $(`.${ELEMENT_CLASS}`);

    $elements.each((index, element) => {
        const $element = $(element);

        const view = new CheckoutPaymentDetailsFormView($element, ELEMENT_CLASS);
    });
});
