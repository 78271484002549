
document.querySelector('#saved-payment-cards')?.addEventListener('change', () => {
    showAddPaymentCardCheckbox();
    changePaymentButton();
    enableSubmitButton();
});

document.addEventListener('DOMContentLoaded', function () {
    //only run these functions if the tag exists
    if (document.querySelector('#saved-payment-cards')) {
        showAddPaymentCardCheckbox();
        changePaymentButton();
        disableSubmitButton();
    }
});

document.querySelector('.c-payment-cards__add-card-details')?.addEventListener('change', function (event) {
    changePaymentButton(event.target.checked);
});

function showAddPaymentCardCheckbox() {
    let paymentCardSelected = document.querySelector('#saved-payment-cards');
    const savePaymentCard = document.querySelector('#save-payment-card');
    const billingSection = document.querySelector('#billing-name-and-address');

    if (paymentCardSelected.selectedIndex === 0) {
        savePaymentCard.classList.remove(`c-payment-cards__hide`);
        billingSection.classList.remove('u-hide');
    } else {
        savePaymentCard.classList.add(`c-payment-cards__hide`);
        billingSection.classList.add('u-hide');
    }
}

function changePaymentButton(savePaymentDetails = false) {
    const button = document.querySelector('.c-checkout-address-form__submit');
    const savedPaymentCard = document.querySelector('#saved-payment-cards');
    const btnPrimary = 'btn-primary';
    const btnSuccess = 'c-checkout-address-form__submit-button';

    if (savedPaymentCard.selectedIndex > 0) {
        button.textContent = 'Pay Now';
        button.classList.remove(btnPrimary);
        button.classList.add(btnSuccess);
    } else if (savePaymentDetails) {
        button.textContent = 'Add card details';
        button.classList.remove(btnSuccess);
        button.classList.add(btnPrimary);
    } else {
        button.textContent = 'Continue to payment';
        button.classList.remove(btnSuccess);
        button.classList.add(btnPrimary);
    }
}

function disableSubmitButton() {
    const submitButton = document.querySelector('.c-checkout-address-form__submit');
    const cardDisabledText = document.querySelector('.c-payment-cards__default-card-expired-text');

    if (cardDisabledText) {
        submitButton.setAttribute('disabled', 'true');
    }
}

function enableSubmitButton() {
    const submitButton = document.querySelector('.c-checkout-address-form__submit');
    const cardDisabledText = document.querySelector('.c-payment-cards__default-card-expired-text');

    if (submitButton.hasAttribute('disabled')) {
        submitButton.removeAttribute('disabled');
    }

    if (cardDisabledText) {
        cardDisabledText.classList.add('u-hide');
    }
}
