/**
 * Checkout payment details form view.
 *
 * Handles submission of the card data to Sage Pay.
 */
import $ from '../../../../assets/js/common/jquery';


export default class CheckoutPaymentDetailsFormView {
    /**
     * Constructor.
     *
     * @param {jQuery} $element The main element.
     * @param {string} mainClass The main CSS class.
     */
    constructor($element, mainClass) {
        this.$element = $element;
        this.mainClass = mainClass;

        this.MAX_TRYS = 3;
        this.SYNC_RESULT_NO_CHANGE = 'RESULT_NO_CHANGE';

        this.tryCount = 0;
        this.merchantSessionKey = null;

        this.getDataFromElement();
        this.findSubElements();
        this.setUpEventHandlers();
    }

    /**
     * Store references to elements for later.
     */
    findSubElements() {
        this.$form = this.$element.find(`.${this.mainClass}__form`);
        this.$cardNumberInput = this.$element.find(`.${this.mainClass}__input--card-number`);
        this.$cardholderNameInput = this.$element.find(`.${this.mainClass}__input--cardholder-name`);
        this.$expiryMonthInput = this.$element.find(`.${this.mainClass}__input--expiry-month`);
        this.$expiryYearInput = this.$element.find(`.${this.mainClass}__input--expiry-year`);
        this.$securityCodeInput = this.$element.find(`.${this.mainClass}__input--security-code`);

        this.$cardIdInput = this.$element.find(`.${this.mainClass}__input--card-id`);
        this.$merchantSessionKeyInput = this.$element.find(`.${this.mainClass}__input--merchant-session-key`);

        this.$errors = this.$element.find(`.${this.mainClass}__errors`);
    }

    /**
     * Get some data from the element.
     */
    getDataFromElement() {
        this.maintainBasketUrl = this.$element.data('maintain-basket-url');
        this.merchantSessionKeyUrl = this.$element.data('merchant-session-key-url');
        this.messages = this.$element.data('messages');
    }

    /**
     * Setup the event handlers.
     */
    setUpEventHandlers() {
        this.$form.submit(this.onFormSubmit.bind(this));
    }

    /**
     * Get the card details from the form.
     *
     * @returns {Object} An object describing the card details.
     */
    getCardDetails() {
        return {
            cardNumber: this.$cardNumberInput.val(),
            cardholderName: this.$cardholderNameInput.val(),
            expiryDate: this.getExpiryDate(),
            securityCode: this.$securityCodeInput.val(),
        };
    }

    /**
     * Get the expiry date from the month and year inputs.
     *
     * @returns {string} The expiry date.
     */
    getExpiryDate() {
        return this.$expiryMonthInput.val() + this.$expiryYearInput.val();
    }

    /**
     * Submit the card details to Sage Pay.
     *
     * @param {string} merchantSessionKey The merchant session key.
     */
    submitCardDetailsToSagePay(merchantSessionKey) {
        try {
            sagepayOwnForm({ merchantSessionKey })
                .tokeniseCardDetails({
                    cardDetails: this.getCardDetails(),
                    onTokenised: this.handleSagePayResult.bind(this),
                });
        } catch (e) {
            this.displayErrors([this.messages.sagePayException]);

            this.merchantSessionKey = null; // Nullify the session key so it is renewed, as it may be at fault.
        }
    }

    /**
     * Handle the result from Sage Pay.
     *
     * @param {Object} result The Sage Pay result.
     */
    handleSagePayResult(result) {
        if (result.success !== undefined && result.success) {
            this.handleSagePaySuccess(result);
        } else {
            this.handleSagePayFailure(result);
        }
    }

    /**
     * Handle a success result from Sage Pay.
     *
     * @param {Object} result The result.
     */
    handleSagePaySuccess(result) {
        // Set some hidden inputs values to what was returned by Sage Pay and submit back to Allio.
        this.$cardIdInput.val(result.cardIdentifier);
        this.$merchantSessionKeyInput.val(this.merchantSessionKey);

        this.$form.trigger('submit', [true]);
    }

    /**
     * Handle a failure result from Sage Pay.
     *
     * @param {Object} result The result.
     */
    handleSagePayFailure(result) {
        // If a 401 error occurs, set the merchant session key to null - it may be invalid/expired - then retry.
        if (result.httpErrorCode === 401) {
            this.merchantSessionKey = null;
            this.tryCount++;

            // This should never happen, but protect against getting into an infinite loop of requests.
            if (this.tryCount < this.MAX_TRYS) {
                this.$form.trigger('submit');

                return;
            }
        }

        // Build a flat array of errors and pass them to the display method.
        if (result.errors !== undefined) {
            const errorArray = [];

            if (result.httpErrorCode === 401) {
                errorArray.push(this.messages.invalidMerchantSessionKey);
            } else {
                for (let i = 0; i < result.errors.length; i++) {
                    errorArray.push(result.errors[i].message);
                }
            }

            this.displayErrors(errorArray);
        }
    }

    /**
     * Display some errors.
     *
     * @param {array} errors An array of error messages.
     */
    displayErrors(errors) {
        const formatted = this.formatErrors(errors);

        this.$errors.append(formatted);
    }

    /**
     * Clear the errors.
     */
    clearErrors() {
        this.$errors.empty();
    }

    /**
     * Format the errors in a Sage Pay response.
     *
     * @param {array} errors An array of error messages.
     *
     * @returns {string} An HTML string containing formatted errors.
     */
    formatErrors(errors) {
        const ERROR_COUNT = errors.length;

        let errorText = '';

        if (ERROR_COUNT > 0) {
            for (let i = 0; i < ERROR_COUNT; i++) {
                errorText += `<li class="${this.mainClass}__error">${errors[i]}</li>`;
            }
        }

        return errorText;
    }

    /**
     * Carry out the requests involving Sage Pay (getting merchant session keys and submitting card details).
     */
    carryOutSagePayRequests() {
        if (this.merchantSessionKey === null) {
            // Retrieve a merchant session key from the server, then send the card details to Sage Pay.
            $.get({
                url: this.merchantSessionKeyUrl,
                success: function (result) {
                    this.merchantSessionKey = result.merchantSessionKey;

                    this.submitCardDetailsToSagePay(this.merchantSessionKey);
                }.bind(this),
                error: function (result) {
                    this.merchantSessionKey = null;

                    this.displayErrors([this.messages.couldNotObtainMerchantSessionKey]);
                }.bind(this),
            });
        } else {
            this.submitCardDetailsToSagePay(this.merchantSessionKey);
        }
    }

    /**
     * Call the maintain basket endpoint.
     *
     * @param {Object} callbacks An object with "success" and "error" keys.
     */
    maintainBasket(callbacks) {
        $.post({
            url: this.maintainBasketUrl,
            success: function (result) {
                if (result.sync === this.SYNC_RESULT_NO_CHANGE) {
                    callbacks.success();
                } else {
                    callbacks.error();
                }
            }.bind(this),
            error(result) {
                callbacks.error();
            },
        });
    }

    /**
     * Event handler for form submission.
     *
     * @param {Event} e The event.
     * @param {boolean} allowSubmit If true, the form submission will not be intercepted.
     */
    onFormSubmit(e, allowSubmit) {
        this.clearErrors();

        if (allowSubmit) {
            return;
        }

        e.preventDefault();

        this.maintainBasket({
            success: function () {
                this.carryOutSagePayRequests();
            }.bind(this),
            error: function () {
                this.displayErrors([this.messages.basketExpired]);
            }.bind(this),
        });
    }
}
