import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
    const hotOffersSplide = new Splide('#hot-offer-carousel', {
        perPage: 4,
        pagination: false,
        gap    : '24px',
        padding: '17px', //will need to match the .slider padding but positive rather than a negative value
        breakpoints: {
            1024: {
                perPage: 3,
            },
            767: {
                perPage: 2,
                arrows: false,
                padding: {right: '20%'}
            },
            480: {
                perPage: 1,
                padding: {right: '20%'}
            },
        },
    });

    hotOffersSplide.mount();
});
