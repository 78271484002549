/**
 * Checkout address form.
 */
import $ from '../../../../assets/js/common/jquery';

import CheckoutAddressFormModel from './checkout-address-form-model';
import CheckoutAddressFormView from './checkout-address-form-view';

$(() => {
    const ELEMENT_CLASS = 'c-checkout-address-form';
    const $elements = $(`.${ELEMENT_CLASS}`);

    $elements.each((index, element) => {
        const $element = $(element);

        // Determine the initial state of the model, based on previously submitted values.

        // Set billingUseSavedContactInformation according to the currently selected radio buttons.
        const $billingUseSavedContactInformationField = $element.find(
            `.${ELEMENT_CLASS}__use-saved-contact-information-fields input:checked`,
        );

        // Set deliverySameAsBillingAddress according to the currently selected radio buttons.
        const $deliverySameAsBillingField = $element.find(
            `.${ELEMENT_CLASS}__delivery-same-as-billing-address-fields input:checked`,
        );

        /*
         * If an address was previously filled in (via lookup or manually), default to manual address entry. This is
         * because if the user is seeing this page again, it is likely because of a validation error. If they used the
         * address lookup, the issue will be with the manual fields that were populated when the address was chosen.
         * Determine if an address was filled in by checking any address field - here we check postcode fields.
         */
        const manualAddressBillingEmpty = $element.find(`.${ELEMENT_CLASS}__manual-address-fields--billing input`).filter(function () {
            return $.trim($(this).val()).length === 0;
        }).length === 4;
        const manualAddressDeliveryEmpty = $element.find(`.${ELEMENT_CLASS}__manual-address-fields--delivery input`).filter(function () {
            return $.trim($(this).val()).length === 0;
        }).length === 4;

        const initialModelState = {
            usingAddressLookupBilling: manualAddressBillingEmpty,
            usingAddressLookupDelivery: manualAddressDeliveryEmpty,
            billingUseSavedContactInformationAddress: $billingUseSavedContactInformationField.val() !== 'new',
            deliverySameAsBillingAddress: $deliverySameAsBillingField.val() !== 'new',
        };

        const model = new CheckoutAddressFormModel(initialModelState);

        const view = new CheckoutAddressFormView($element, ELEMENT_CLASS, model);
    });

    document.querySelector('#edit-address-details')?.addEventListener('click', (e) => {
        e.preventDefault();
        const confirm = window.confirm(e.target.dataset.confirm);

        if (confirm) {
            window.location.replace(e.target.href);
        }
    });

    document.querySelector('.c-checkout-address-form > form')?.addEventListener('submit', (e) => {
        document.querySelector('.c-checkout-address-form__submit').setAttribute('disabled', 'disabled');
        return true;
    });
});
