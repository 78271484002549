/**
 * Product list sort
 *
 * Functionality for submitting the product list form when different options are selected.
 */

import $ from '../../../../assets/js/common/jquery';


const COMPONENT_CLASS = 'c-product-list-sort';
const ELEMENT_CLASS = '__select';
const URL_BASED_CLASS = `${COMPONENT_CLASS + ELEMENT_CLASS}--url-based`;

$(() => {
    const sortSelects = document.querySelectorAll(`.${COMPONENT_CLASS}${ELEMENT_CLASS}`);

    sortSelects.forEach((sortSelect) => {
        const isUrlBased = sortSelect.classList.contains(URL_BASED_CLASS);

        const takeAction = function () {
            if (isUrlBased) {
                window.location = sortSelect.options[sortSelect.selectedIndex].value;
            } else {
                sortSelect.closest('form').submit();
            }
        };

        if (isUrlBased) {
            // Set the option that matches the current URL to be selected.
            const url = window.location.href;

            for (const i in sortSelect.options) {
                if (sortSelect.options.hasOwnProperty(i)) {
                    const option = sortSelect.options[i];

                    if (option.value === url) {
                        sortSelect.selectedIndex = i;
                    }
                }
            }
        }

        sortSelect.addEventListener('change', takeAction);
    });
});
