/**
 * Model to represent an evoucher amount selector.
 */
export default class EvoucherAmountSelectorModel {
    /**
     * Constructor.
     *
     * @param {array} denominations An array of Denomination objects.
     * @param {number} discountPercentage The discount percentage.
     */
    constructor(denominations, discountPercentage) {
        this.denominations = denominations;
        this.discountPercentage = discountPercentage;

        this.activeDenomination = null;
        this.quantity = 0;

        this.calculateMode();
    }

    /**
     * Work out if this selector should run in predenominated or nondenominated mode from the available denominations.
     */
    calculateMode() {
        let mode = null;

        // Decide the mode based on denominations with available stock (favouring nondenominated mode if available).
        if (this.hasNondenominatedDenominationWithStock()) {
            mode = 'nondenominated';
        } else if (this.hasPredenominatedDenominationWithStock()) {
            mode = 'predenominated';
        }

        /*
         * If we get here, we have no stock, but still need to show the correct UI to inform the user as to what
         * denominations are usually available, or if the voucher is nondenominated. Again, favour nondenominated, if
         * that denomination is available to us.
         */
        if (mode === null) {
            mode = this.hasNondenominatedDenomination() ? 'nondenominated' : 'predenominated';
        }

        this.mode = mode;
    }

    /**
     * Determine if this model is in predenominated mode.
     *
     * @return {bool} True if the model is in predenominated mode.
     */
    isPredenominated() {
        return this.mode === 'predenominated';
    }

    /**
     * Determine if this model is in nondenominated mode.
     *
     * @return {bool} True if the model is in nondenominated mode.
     */
    isNondenominated() {
        return !this.isPredenominated();
    }

    /**
     * Determine if this model has at least one denomination set.
     *
     * @return {bool} True if there is at least one denomination.
     */
    hasDenominations() {
        return this.denominations.length > 0;
    }

    /**
     * Determine if there is stock available for any of the denominations.
     *
     * @return {bool} True if any of the denominations have stock available.
     */
    hasStock() {
        let hasStock = false;

        this.denominations.forEach((denomination) => {
            if (denomination.hasStock()) {
                hasStock = true;
            }
        });

        return hasStock;
    }

    /**
     * Calculate the total cost (with discount applied) of a given number of vouchers.
     *
     * @return {number} The discounted total in pence.
     */
    calculateDiscountedTotal() {
        return Math.round(
            this.activeDenomination.getUnitValue() * this.quantity / 100 * (100 - this.discountPercentage),
        );
    }

    /**
     * Calculate the Recommended Retail Price of a given number of vouchers.
     *
     * @return {number} The RRP in pence.
     */
    calculateRrp() {
        return (this.quantity * this.activeDenomination.getUnitValue());
    }

    /**
     * Calculate the saving (RRP - discounted total).
     *
     * @return {number} The saving in pence.
     */
    calculateSaving() {
        // Call Math.abs as sometimes we get -0 as the value when dealing with non-discounted vouchers.
        return Math.abs(this.calculateRrp() - this.calculateDiscountedTotal());
    }

    /**
     * Determine if the quantity can be incremented.
     *
     * @return {bool} True if the quantity can be incremented.
     */
    canQuantityBeIncremented() {
        return this.canQuantityBePurchased(this.quantity + 1);
    }

    /**
     * Determine if the quantity can be decremented.
     *
     * @return {bool} True if the quantity can be decremented.
     */
    canQuantityBeDecremented() {
        return this.canQuantityBePurchased(this.quantity - 1);
    }

    /**
     * Determine if the quantity can be set to a particular value.
     *
     * @param {Number} quantity The quantity.
     *
     * @return {bool} True if the quantity can be set to the value.
     */
    canQuantityBePurchased(quantity) {
        return this.activeDenomination !== null && this.activeDenomination.canQuantityBePurchased(quantity);
    }

    /**
     * Determine if the active denomination and quantity can be submitted.
     *
     * @return {bool} True if the denomination/quantity combination can be submitted.
     */
    canBeSubmitted() {
        return this.hasStock() && this.activeDenomination !== null && this.quantity > 0;
    }

    /**
     * Get a denomination by its denomination amount.
     *
     * @param {int} amount The denomination amount.
     *
     * @return {Denomination|undefined} The denomination matching the amount, or undefined if it was not found.
     */
    getDenominationByDenominationAmount(amount) {
        return this.denominations.find((denomination) => denomination.denomination === amount);
    }

    /**
     * Determine if there is a predenominated denomination on this object.
     *
     * @return {bool} True if a predenominated denomination exists in this object's denominations.
     */
    hasPredenominatedDenomination() {
        const foundDenominations = this.denominations.find((denomination) => denomination.denomination !== 0);

        return typeof foundDenominations !== 'undefined';
    }

    /**
     * Determine if there is a nondenominated denomination on this object.
     *
     * @return {bool} True if a nondenominated denomination exists in this object's denominations.
     */
    hasNondenominatedDenomination() {
        const foundDenominations = this.denominations.find((denomination) => denomination.denomination === 0);

        return typeof foundDenominations !== 'undefined';
    }

    /**
     * Determine if there is a predenominated denomination with stock on this object.
     *
     * @return {bool} True if a predenominated denomination with stock exists in this object's denominations.
     */
    hasPredenominatedDenominationWithStock() {
        const foundDenominations = this.denominations.find((denomination) => denomination.denomination !== 0 && denomination.hasStock());

        return typeof foundDenominations !== 'undefined';
    }

    /**
     * Determine if there is a nondenominated denomination with stock on this object.
     *
     * @return {bool} True if a nondenominated denomination with stock exists in this object's denominations.
     */
    hasNondenominatedDenominationWithStock() {
        const foundDenominations = this.denominations.find((denomination) => denomination.denomination === 0 && denomination.hasStock());

        return typeof foundDenominations !== 'undefined';
    }
}
