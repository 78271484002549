/**
 * Client Admin FAQ accordion.
 *
 * Displays an initially hidden section of content which is displayed by clicking the "Show" hyperlink,
 * and is hidden by clicking the "Hide" hyperlink.
 */

import $ from '../../../../assets/js/common/jquery';


$(() => {
    const FAQ_ACCORDION_PANEL_SELECTOR = '.c-faq-list__item-body';
    const FAQ_ACCORDION_LINK = '.c-faq-list__item-read-more-link';
    const FAQ_ACCORDION_TEXT_SHOW = $(FAQ_ACCORDION_LINK).data('show');
    const FAQ_ACCORDION_TEXT_HIDE = $(FAQ_ACCORDION_LINK).data('hide');

    $(FAQ_ACCORDION_LINK).click(function (e) {
        // Prevent the hyperlink from firing
        e.preventDefault();

        // Variables to store the reference to the elements
        const accordionPanel = $(this).parent().children(FAQ_ACCORDION_PANEL_SELECTOR);

        // Toggle the visibility of the panel
        accordionPanel.toggle();

        // Switch the hyperlink text depending on
        // whether the panel is displayed or hidden
        if (accordionPanel.is(':visible')) {
            $(this).text(FAQ_ACCORDION_TEXT_HIDE);
            accordionPanel.attr('aria-hidden', false);
        } else {
            $(this).text(FAQ_ACCORDION_TEXT_SHOW);
            accordionPanel.attr('aria-hidden', true);
        }
    });
});
