const config = {
    pillSelector: 'js-cashback-tab',
    contentSelector: 'c-pnm__content',
    activePillClass: 'bg-primary',
    hideContentClass: 'u-hide',
};

class Pill {
    /**
     * Constructor for Pills object.
     */
    constructor() {
        const pills = document.getElementsByClassName(config.pillSelector);
        const contentCollection = document.getElementsByClassName(config.contentSelector);

        /**
         * This conditional is a fallback for IE11 on WIN8.1, not needed in Edge or any modern browser
         */
        if (!Array.from) {
            const pillCount = pills.length;
            const contentCount = contentCollection.length;

            this.pillsArray = [];
            this.contentArray = [];

            for (let i = 0; i < pillCount; i++) {
                this.pillsArray.push(pills[i]);
            }

            for (let i = 0; i < contentCount; i++) {
                this.contentArray.push(contentCollection[i]);
            }
        } else {
            this.pillsArray = Array.from(pills);
            this.contentArray = Array.from(contentCollection);
        }

        this.bindPillsClicks();
    }

    /**
     * Binding click events on pills.
     */
    bindPillsClicks() {
        this.pillsArray.forEach((pill) => {
            pill.onclick = (event) => {
                const contentId = event.target.getAttribute('data');
                const activeContent = document.getElementById(contentId);

                this.removePillsBgClass();
                event.target.classList.add(config.activePillClass);

                this.hideAllContent();
                activeContent.classList.remove(config.hideContentClass);
            };
        });
    }

    /**
     * Removing background class for all pills.
     */
    removePillsBgClass() {
        this.pillsArray.forEach((object) => {
            object.classList.remove(config.activePillClass);
        });
    }

    /**
     * Hiding all content for all pills.
     */
    hideAllContent() {
        this.contentArray.forEach((object) => {
            object.classList.add(config.hideContentClass);
        });
    }
}

/**
 * Initialise Pills object.
 */
window.onload = () => {
    new Pill();
};
