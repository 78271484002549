const productCarousel = $('#carousel-hero-home');

productCarousel.carousel('pause');


document.querySelector('#carousel-remote')?.addEventListener('click', function (e) {
    const control = e.target
    let isPaused = productCarousel[0].dataset.isPaused

    if (isPaused === "true") {
        productCarousel.carousel('cycle');
        control.classList.add('glyphicon-pause');
        control.classList.remove('glyphicon-play');
        productCarousel[0].dataset.isPaused = false;
    } else {
        productCarousel.carousel('pause');
        control.classList.remove('glyphicon-pause');
        control.classList.add('glyphicon-play');
        productCarousel[0].dataset.isPaused = true;
    }
});


