/**
 * Model to represent a checkout address form.
 */
export default class CheckoutAddressFormModel {
    /**
     * Constructor.
     *
     * @param {Object} initialState The initial state of the model.
     */
    constructor(initialState) {
        this.billingUseSavedContactInformationAddress = initialState.billingUseSavedContactInformationAddress;
        this.usingAddressLookupBilling = initialState.usingAddressLookupBilling;
        this.usingAddressLookupDelivery = initialState.usingAddressLookupDelivery;
        this.deliverySameAsBillingAddress = initialState.deliverySameAsBillingAddress;
    }

    /**
     * Determine if the forename and surname fields for billing address should be shown.
     *
     * @returns {boolean} True if the fields should be shown.
     */
    shouldShowNameFieldsForBilling() {
        return !this.billingUseSavedContactInformationAddress;
    }

    /**
     * Determine if the address lookup fields should show for the billing address.
     *
     * @returns {boolean} True if the fields should show.
     */
    shouldShowAddressLookupFieldsForBilling() {
        return !this.billingUseSavedContactInformationAddress && this.usingAddressLookupBilling;
    }

    /**
     * Determine if the manual address fields should show for the billing address.
     *
     * @returns {boolean} True if the fields should show.
     */
    shouldShowManualAddressFieldsForBilling() {
        return !this.billingUseSavedContactInformationAddress && !this.usingAddressLookupBilling;
    }

    /**
     * Determine if the link to enable the address lookup should show for the billing address.
     *
     * @returns {boolean} True if the link should show.
     */
    shouldShowUseAddressLookupLinkForBilling() {
        return !this.billingUseSavedContactInformationAddress && !this.usingAddressLookupBilling;
    }

    /**
     * Determine if the link to enable manual address entry should show for the billing address.
     *
     * @returns {boolean} True if the link should show.
     */
    shouldShowUseManualAddressLinkForBilling() {
        return !this.billingUseSavedContactInformationAddress && this.usingAddressLookupBilling;
    }

    /**
     * Determine if the address lookup fields should show for the delivery address.
     *
     * @returns {boolean} True if the fields should show.
     */
    shouldShowAddressLookupFieldsForDelivery() {
        return this.usingAddressLookupDelivery && !this.deliverySameAsBillingAddress;
    }

    /**
     * Determine if the forename and surname fields for delivery address should be shown.
     *
     * @returns {boolean} True if the fields should be shown.
     */
    shouldShowNameFieldsForDelivery() {
        return !this.deliverySameAsBillingAddress;
    }

    /**
     * Determine if the manual address fields should show for the delivery address.
     *
     * @returns {boolean} True if the fields should show.
     */
    shouldShowManualAddressFieldsForDelivery() {
        return !this.usingAddressLookupDelivery && !this.deliverySameAsBillingAddress;
    }

    /**
     * Determine if the link to enable the address lookup should show for the delivery address.
     *
     * @returns {boolean} True if the link should show.
     */
    shouldShowUseAddressLookupLinkForDelivery() {
        return !this.usingAddressLookupDelivery && !this.deliverySameAsBillingAddress;
    }

    /**
     * Determine if the link to enable manual address entry should show for the delivery address.
     *
     * @returns {boolean} True if the link should show.
     */
    shouldShowUseManualAddressLinkForDelivery() {
        return this.usingAddressLookupDelivery && !this.deliverySameAsBillingAddress;
    }

    /**
     * Mutator method.
     *
     * @param {boolean} billingUseSavedContactInformationAddress The new value of the property.
     */
    setBillingUseSavedContactInformationAddress(billingUseSavedContactInformationAddress) {
        this.billingUseSavedContactInformationAddress = billingUseSavedContactInformationAddress;

        this.usingAddressLookupBilling = !billingUseSavedContactInformationAddress;
        this.usingAddressLookupDelivery = !billingUseSavedContactInformationAddress;
    }

    /**
     * Mutator method.
     *
     * @param {boolean} deliverySameAsBillingAddress The new value of the property.
     */
    setDeliverySameAsBillingAddress(deliverySameAsBillingAddress) {
        this.deliverySameAsBillingAddress = deliverySameAsBillingAddress;
    }

    /**
     * Mutator method.
     *
     * @param {boolean} usingAddressLookupBilling The new value of the property.
     */
    setUsingAddressLookupBilling(usingAddressLookupBilling) {
        this.usingAddressLookupBilling = usingAddressLookupBilling;
    }

    /**
     * Mutator method.
     *
     * @param {boolean} usingAddressLookupDelivery The new value of the property.
     */
    setUsingAddressLookupDelivery(usingAddressLookupDelivery) {
        this.usingAddressLookupDelivery = usingAddressLookupDelivery;
    }
}
