import $ from '../../../../assets/js/common/jquery';


/**
 * ShoppingVoucher amount selector view.
 */
export default class ShoppingVoucherAmountSelectorView {
    /**
     * Constructor.
     *
     * @param {jQuery} $element The main element.
     * @param {string} mainClass The main CSS class.
     * @param {ShoppingVoucherAmountSelectorModel} model The main CSS class.
     * @param {Object} messages A hash of messages.
     */
    constructor($element, mainClass, model, messages) {
        this.$element = $element;
        this.mainClass = mainClass;
        this.model = model;
        this.messages = messages;

        this.denominationLinks = [];

        this.generateConfig();
        this.findSubElements();
        this.transformUi();
    }

    /**
     * Generate some configuration data.
     */
    generateConfig() {
        const DENOMINATION_LINK_CLASS = `${this.mainClass}__denomination-link`;
        const HEADING_CLASS = `${this.mainClass}__heading`;
        const INNER_CLASS = `${this.mainClass}__inner`;
        const MOBILE_OPEN_LINK_CLASS = `${this.mainClass}__mobile-open-link`;
        const MOBILE_OPEN_LINK_CONTAINER_CLASS = `${this.mainClass}__mobile-open-link-container`;
        const QUANTITY_BUTTON_CLASS = `${this.mainClass}__quantity-button`;
        const QUANTITY_INPUT_CLASS = `${this.mainClass}__quantity-input`;
        const SUBMIT_CLASS = `${this.mainClass}__submit`;

        this.config = {
            currencySymbol: '£',
            dataKeys: {
                denomination: 'denomination',
            },
            classes: {
                summaryRrp: 'c-product-widget-costs-summary__value--rrp',
                summaryTotal: 'c-product-widget-costs-summary__value--total',
                summaryYouSave: 'c-product-widget-costs-summary__value--you-save',
                denomination: `${this.mainClass}__denomination`,
                denominationControls: `${this.mainClass}__denomination-controls`,
                denominationErrorMessage: `${this.mainClass}__denomination-error-message`,
                denominationLi: `${this.mainClass}__denomination-list-item`,
                denominationLink: DENOMINATION_LINK_CLASS,
                denominationLinkActive: `${DENOMINATION_LINK_CLASS}--active`,
                denominationList: `${this.mainClass}__denomination-list`,
                denominationSelect: `${this.mainClass}__denomination-select`,
                form: `${this.mainClass}__form`,
                heading: HEADING_CLASS,
                headingQuantity: `${HEADING_CLASS}--quantity`,
                inner: INNER_CLASS,
                innerMobileClosed: `${INNER_CLASS}--mobile-closed`,
                mainErrorMessage: `${this.mainClass}__main-error-message`,
                mobileCloseLink: `${this.mainClass}__mobile-close-link`,
                mobileCloseLinkContainer: `${this.mainClass}__mobile-close-link-container`,
                mobileOpenLink: MOBILE_OPEN_LINK_CLASS,
                mobileOpenLinkContainer: MOBILE_OPEN_LINK_CONTAINER_CLASS,
                mobileOpenLinkContainerHidden: `${MOBILE_OPEN_LINK_CONTAINER_CLASS}--hidden`,
                mobileOpenLinkDisabled: `${MOBILE_OPEN_LINK_CLASS}--disabled`,
                nonJs: `${this.mainClass}--no-js`,
                quantityButton: QUANTITY_BUTTON_CLASS,
                quantityButtonDisabled: `${QUANTITY_BUTTON_CLASS}--disabled`,
                quantityControls: `${this.mainClass}__quantity-controls`,
                quantityIndicator: `${this.mainClass}__quantity-indicator`,
                quantityInput: QUANTITY_INPUT_CLASS,
                quantityMinus: `${QUANTITY_BUTTON_CLASS}--minus`,
                quantityPlus: `${QUANTITY_BUTTON_CLASS}--plus`,
                submit: SUBMIT_CLASS,
                submitDisabled: `${SUBMIT_CLASS}--disabled`,
            },
        };
    }

    /**
     * Store references to elements for later.
     */
    findSubElements() {
        this.$inner = this.$element.find(`.${this.config.classes.inner}`);
        this.$mainErrorMessage = this.$element.find(`.${this.config.classes.mainErrorMessage}`);

        this.$denominationControls = this.$element.find(`.${this.config.classes.denominationControls}`);
        this.$quantityControls = this.$element.find(`.${this.config.classes.quantityControls}`);

        this.$summaryTotal = this.$element.find(`.${this.config.classes.summaryTotal}`);
        this.$summaryRrp = this.$element.find(`.${this.config.classes.summaryRrp}`);
        this.$summaryYouSave = this.$element.find(`.${this.config.classes.summaryYouSave}`);

        this.$form = this.$element.find(`.${this.config.classes.form}`);
        this.$denominationSelect = this.$element.find(`.${this.config.classes.denominationSelect}`);
        this.$quantityInput = this.$element.find(`.${this.config.classes.quantityInput}`);
        this.$submit = this.$element.find(`.${this.config.classes.submit}`);

        this.$mobileOpenLinkContainer = this.$element.find(`.${this.config.classes.mobileOpenLinkContainer}`);
        this.$mobileOpenLink = this.$element.find(`.${this.config.classes.mobileOpenLink}`);

        this.$mobileCloseLinkContainer = this.$element.find(`.${this.config.classes.mobileCloseLinkContainer}`);
        this.$mobileCloseLink = this.$element.find(`.${this.config.classes.mobileCloseLink}`);
    }

    /**
     * Transform the user interface.
     */
    transformUi() {
        this.$element.removeClass(this.config.classes.nonJs);

        this.addDenominationUi();
        this.addQuantityUi();

        this.renderMobileOpenLink();
        this.renderNoStockError();
        this.renderQuantityButtons();
        this.renderSubmitButton();

        this.setupEventHandlers();
    }

    /**
     * Setup the event handlers.
     */
    setupEventHandlers() {
        this.$form.submit(this.onFormSubmit.bind(this));

        this.$mobileOpenLink.click(this.onMobileOpenLinkClick.bind(this));
        this.$mobileCloseLink.click(this.onMobileCloseLinkClick.bind(this));

        this.$quantityPlusButton.click(this.onQuantityPlusClick.bind(this));
        this.$quantityMinusButton.click(this.onQuantityMinusClick.bind(this));

        this.denominationLinks.forEach(($denominationLink) => {
            $denominationLink.click(this.onDenominationClick.bind(this));
        });
    }

    /**
     * Add the UI for selecting denominations.
     */
    addDenominationUi() {
        if (this.model.hasDenominations()) {
            const $heading = $(`<h3 class="${this.config.classes.heading}">${this.messages.chooseAmount}</h3>`);

            this.$denominationErrorMessage = $(`<p class="${this.config.classes.denominationErrorMessage}"></p>`);

            const $denominationList = $(`<ul class="${this.config.classes.denominationList}"></ul>`);

            // Display a list item and link for each denomination available.
            this.model.denominations.forEach((denomination) => {
                const $denominationLink = $(`<a href="#" class="${this.config.classes.denominationLink}">${
                    this.config.currencySymbol}${denomination}</a>`);

                $denominationLink.data(this.config.dataKeys.denomination, denomination);

                const $denominationLi = $(`<li class="${this.config.classes.denominationLi}"></li>`);

                $denominationLi.append($denominationLink);

                $denominationList.append($denominationLi);

                this.denominationLinks.push($denominationLink);
            });

            this.$denominationControls.append($heading)
                .append(this.$denominationErrorMessage)
                .append($denominationList);
        }
    }

    /**
     * Set the active denomination.
     *
     * @param {Denomination} denomination A denomination.
     */
    setDenomination(denomination) {
        // Don't do anything if the user re-clicked the active denomination.
        if (this.model.activeDenomination === denomination) {
            return;
        }

        this.model.activeDenomination = denomination;

        this.renderDenominationLinks();
        this.hideNoDenominationSelectedError();

        this.setQuantity(0);
    }

    /**
     * Add the UI for selecting quantities.
     */
    addQuantityUi() {
        const $heading = $(`<h3 class="${this.config.classes.heading} ${this.config.classes.headingQuantity
        }">${this.messages.quantity}</h3>`);

        this.$quantityMinusButton = $(`<a href="#" class="${this.config.classes.quantityButton} ${
            this.config.classes.quantityMinus} ${this.config.classes.quantityButtonDisabled}"><i class="fa fa-minus" aria-hidden="true"></i></a>`);

        this.$quantityIndicator = $(`<span class="${this.config.classes.quantityIndicator}">0</span>`);

        this.$quantityPlusButton = $(`<a href="#" class="${this.config.classes.quantityButton} ${
            this.config.classes.quantityPlus}"><i class="fa fa-plus" aria-hidden="true"></i></a>`);

        this.$quantityControls.append($heading)
            .append(this.$quantityMinusButton)
            .append(this.$quantityIndicator)
            .append(this.$quantityPlusButton);
    }

    /**
     * Set the quantity to a specific number.
     *
     * @param {number} quantity The quantity to set.
     */
    setQuantity(quantity) {
        this.model.quantity = quantity;

        this.renderQuantityChangeElements();
    }

    /**
     * Render the link for displaying the UI for mobile devices.
     */
    renderMobileOpenLink() {
        if (!this.model.hasDenominations()) {
            this.$mobileOpenLink.addClass(this.config.classes.mobileOpenLinkDisabled)
                .html(this.messages.outOfStockMessage);
        }
    }

    /**
     * Render the quantity buttons.
     */
    renderQuantityButtons() {
        if (this.model.activeDenomination !== null && this.model.canQuantityBeDecremented()) {
            this.enableQuantityButton(this.$quantityMinusButton);
        } else {
            this.disableQuantityButton(this.$quantityMinusButton);
        }

        if (this.model.activeDenomination !== null) {
            this.enableQuantityButton(this.$quantityPlusButton);
        } else {
            this.disableQuantityButton(this.$quantityPlusButton);
        }
    }

    /**
     * Render the quantity indicator.
     */
    renderQuantityIndicator() {
        this.$quantityIndicator.html(this.model.quantity);
        this.$quantityInput.val(this.model.quantity);
    }

    /**
     * Update the costs and savings values to represent the currently selected denomination and quantity.
     */
    renderCostsAndSavings() {
        let total = 0;
        let rrp = 0;
        let saving = 0;

        if (this.model.activeDenomination !== null) {
            total = this.model.calculateDiscountedTotal();
            rrp = this.model.calculateRrp();
            saving = this.model.calculateSaving();
        }

        this.$summaryTotal.html(this.formatCurrency(total));
        this.$summaryRrp.html(this.formatCurrency(rrp));

        const youSave = `${this.formatCurrency(saving)} (${this.model.discountPercentage}%)`;
        this.$summaryYouSave.html(youSave);
    }

    /**
     * Enable or disable the submit button based on the current selection.
     */
    renderSubmitButton() {
        if (this.model.canBeSubmitted()) {
            this.$submit.removeClass(this.config.classes.submitDisabled);
        } else {
            this.$submit.addClass(this.config.classes.submitDisabled);
        }

        if (!this.model.hasDenominations()) {
            this.$submit.html(this.messages.outOfStockMessage);
        }
    }

    /**
     * Render the elements that change when quantities change.
     */
    renderQuantityChangeElements() {
        this.renderQuantityButtons();
        this.renderQuantityIndicator();
        this.renderCostsAndSavings();
        this.renderSubmitButton();
    }

    /**
     * Render the mobile user interface.
     */
    renderMobileUserInterface() {
        if (this.model.mobileUiVisible) {
            this.$inner.removeClass(this.config.classes.innerMobileClosed);

            this.$mobileOpenLinkContainer.addClass(this.config.classes.mobileOpenLinkContainerHidden);
        } else {
            this.$inner.addClass(this.config.classes.innerMobileClosed);

            this.$mobileOpenLinkContainer.removeClass(this.config.classes.mobileOpenLinkContainerHidden);
        }
    }

    /**
     * Render the denomination links.
     */
    renderDenominationLinks() {
        this.denominationLinks.forEach(($link) => {
            const denomination = $link.data(this.config.dataKeys.denomination);

            if (this.model.activeDenomination === denomination) {
                $link.addClass(this.config.classes.denominationLinkActive);

                this.$denominationSelect.val(denomination);
            } else {
                $link.removeClass(this.config.classes.denominationLinkActive);
            }
        });
    }

    /**
     * Display a currency amount.
     *
     * @param {number} amount The amount to display in pence.
     *
     * @return {string} A formatted currency amount.
     */
    formatCurrency(amount) {
        return this.config.currencySymbol + (amount / 100).toFixed(2);
    }

    /**
     * Enable a quantity button.
     *
     * @param {jQuery} $button The button to activate.
     */
    enableQuantityButton($button) {
        $button.removeClass(this.config.classes.quantityButtonDisabled);
    }

    /**
     * Disable a quantity button.
     *
     * @param {jQuery} $button The button to deactivate.
     */
    disableQuantityButton($button) {
        $button.addClass(this.config.classes.quantityButtonDisabled);
    }

    /**
     * Display an error message, telling the user they need to choose a denomination.
     */
    displayNoDenominationSelectedError() {
        this.$denominationErrorMessage.html(this.messages.noDenominationSelected);
    }

    /**
     * Hide the error message displayed when the user has not selected a denomination.
     */
    hideNoDenominationSelectedError() {
        this.$denominationErrorMessage.empty();
    }

    /**
     * Handler for clicks on denominations.
     *
     * @param {event} e The click event.
     */
    onDenominationClick(e) {
        e.preventDefault();

        const $denominationLink = $(e.target);
        const denomination = $denominationLink.data(this.config.dataKeys.denomination);

        this.setDenomination(denomination);
    }

    /**
     * Handler for clicks on the quantity plus button.
     *
     * @param {event} e The click event.
     */
    onQuantityPlusClick(e) {
        e.preventDefault();

        if (!this.model.hasDenominations()) {
            return;
        }

        if (this.model.activeDenomination === null) {
            this.displayNoDenominationSelectedError();

            return;
        }

        this.setQuantity(this.model.quantity + 1);
    }

    /**
     * Handler for clicks on the quantity minus button.
     *
     * @param {event} e The click event.
     */
    onQuantityMinusClick(e) {
        e.preventDefault();

        if (!this.model.hasDenominations()) {
            return;
        }

        if (this.model.activeDenomination === null) {
            this.displayNoDenominationSelectedError();

            return;
        }

        if (this.model.canQuantityBeDecremented()) {
            this.setQuantity(this.model.quantity - 1);
        }
    }

    /**
     * Handler for clicks on the mobile open link.
     *
     * @param {event} e The click event.
     */
    onMobileOpenLinkClick(e) {
        // Don't call e.preventDefault() here, so we scroll to the top of the form.

        if (this.model.hasDenominations()) {
            this.model.mobileUiVisible = true;

            this.renderMobileUserInterface();
        }
    }

    /**
     * Handler for clicks on the mobile close link.
     *
     * @param {event} e The click event.
     */
    onMobileCloseLinkClick(e) {
        e.preventDefault();

        this.model.mobileUiVisible = false;

        this.renderMobileUserInterface();
    }

    /**
     * Handler for form submission.
     *
     * @param {event} e The submit event.
     */
    onFormSubmit(e) {
        if (!this.model.canBeSubmitted()) {
            e.preventDefault();
        }
    }
}
