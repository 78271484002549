document.addEventListener('DOMContentLoaded', () => {

    // Manually declaring as planning to make universal
    // component in the future, querySelectorAll will make
    // this a nightmare
    let dropDownButton = document.querySelector('.favourites-bar__favourites-dropdown')
    let favouritesList = document.querySelector('.favourites-bar__favourites-list')
    let indicator = document.querySelector('.favourites-bar__favourites-dropdown-caret')

    // Separate events for click and focus, so we don't need to track state
    dropDownButton.addEventListener('click', event => buttonPress(event, favouritesList, indicator))
    dropDownButton.addEventListener('focusout', event => focusOut(event, favouritesList, indicator))
});

function buttonPress(event, target, indicator) {

    // Button pressed, toggle visibility, indicator rotation and aria-expanded
    event.target.ariaExpanded = event.target.ariaExpanded !== 'true';
    target.classList.toggle('favourites-bar__favourites-list--visible')
    indicator.classList.toggle('favourites-bar__favourites-dropdown-caret--rotate')
}

function focusOut(event, target, indicator) {
    // Check where focus is going to preserve A links
    let relatedTagName = event.relatedTarget ? event.relatedTarget.tagName : 'Unknown'
    if (relatedTagName !== 'A') {
        event.target.ariaExpanded = 'false'
        target.classList.remove('favourites-bar__favourites-list--visible')
        indicator.classList.remove('favourites-bar__favourites-dropdown-caret--rotate')
    }

}