/**
 * Evoucher amount selector.
 */
import $ from '../../../../assets/js/common/jquery';

import EvoucherAmountSelectorModel from './evoucher-amount-selector-model';
import EvoucherAmountSelectorView from './evoucher-amount-selector-view';
import Denomination from './denomination';

$(() => {
    const ELEMENT_CLASS = 'c-evoucher-amount-selector';
    const $amountSelectors = $(`.${ELEMENT_CLASS}`);

    $amountSelectors.each((index, element) => {
        const $element = $(element);

        const DATA_KEYS = {
            denominations: 'denominations',
            discountPercentage: 'discount-percentage',
            messages: 'messages',
        };

        const denominations = $element.data(DATA_KEYS.denominations);
        const discountPercentage = $element.data(DATA_KEYS.discountPercentage);
        const messages = $element.data(DATA_KEYS.messages);

        const denominationValueObjects = [];

        for (const denomination in denominations) {
            const quantityAvailable = denominations[denomination];

            denominationValueObjects.push(
                new Denomination(parseInt(denomination, 10), parseInt(quantityAvailable, 10)),
            );
        }

        const model = new EvoucherAmountSelectorModel(
            denominationValueObjects,
            parseFloat(discountPercentage),
        );

        const view = new EvoucherAmountSelectorView($element, ELEMENT_CLASS, model, messages);
    });
});
