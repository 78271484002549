/**
 * Reloadable card amount selector.
 */
import $ from '../../../../assets/js/common/jquery';

import ReloadableCardAmountSelectorModel from './reloadable-card-amount-selector-model';
import ReloadableCardAmountSelectorView from './reloadable-card-amount-selector-view';

$(() => {
    const ELEMENT_CLASS = 'c-reloadable-card-amount-selector';
    const amountSelectors = document.querySelectorAll(`.${ELEMENT_CLASS}`);

    amountSelectors.forEach((element) => {
        const { minimumAmount } = element.dataset;
        const { maximumAmount } = element.dataset;
        const { discountPercentage } = element.dataset;
        const { topUpCards } = element.dataset;
        const { canBuyNewCard } = element.dataset;
        const { messages } = element.dataset;

        const model = new ReloadableCardAmountSelectorModel(
            parseInt(minimumAmount, 10),
            parseInt(maximumAmount, 10),
            parseFloat(discountPercentage),
            JSON.parse(topUpCards),
            canBuyNewCard,
        );

        const view = new ReloadableCardAmountSelectorView(element, ELEMENT_CLASS, model, JSON.parse(messages));
    });
});
