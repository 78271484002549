/**
 * Copyable input JS.
 */

import $ from '../../../../assets/js/common/jquery';


const INPUT_SELECTOR = '.js-copyable-input';
const MESSAGE_TIMEOUT_MS = 2000;

$(() => {
    const inputs = document.querySelectorAll(INPUT_SELECTOR);

    inputs.forEach((input) => {
        // Add a copy button next to each element.
        const button = document.createElement('span');
        button.textContent = 'Copy';
        button.className = 'c-copyable-input__button';

        button.onclick = () => {
            if (input.value.length > 0) {
                input.select();
                document.execCommand('copy');

                button.classList.add('is-copied');

                // Display a message indicating the copy succeeded, removing the message after a time.
                setTimeout(() => {
                    button.classList.remove('is-copied');
                }, MESSAGE_TIMEOUT_MS);
            }
        };

        input.parentElement.insertBefore(button, input.nextSibling); // Add the copy button to the DOM after the input.
    });
});
