import $ from '../../../../assets/js/common/jquery';

import Denomination from './denomination';

/**
 * Evoucher amount selector view.
 */
export default class EvoucherAmountSelectorView {
    /**
     * Constructor.
     *
     * @param {jQuery} $element The main element.
     * @param {string} mainClass The main CSS class.
     * @param {EvoucherAmountSelectorModel} model The main CSS class.
     * @param {Object} messages A hash of messages.
     */
    constructor($element, mainClass, model, messages) {
        this.$element = $element;
        this.mainClass = mainClass;
        this.model = model;
        this.messages = messages;

        this.denominationLinks = [];

        this.generateConfig();
        this.findSubElements();
        this.transformUi();
    }

    /**
     * Generate some configuration data.
     */
    generateConfig() {
        const DENOMINATION_LINK_CLASS = `${this.mainClass}__denomination-link`;
        const HEADING_CLASS = `${this.mainClass}__heading`;
        const QUANTITY_BUTTON_CLASS = `${this.mainClass}__quantity-button`;
        const QUANTITY_FORM_ROW_CLASS = `${this.mainClass}__form-row--quantity`;
        const QUANTITY_INPUT_CLASS = `${this.mainClass}__quantity-input`;
        const QUANTITY_LABEL_CLASS = `${this.mainClass}__quantity-label`;
        const SUBMIT_CLASS = `${this.mainClass}__submit`;

        this.config = {
            currencySymbol: '£',
            dataKeys: {
                denomination: 'denomination',
            },
            classes: {
                summaryRrp: 'c-product-widget-costs-summary__value--rrp',
                summaryTotal: 'c-product-widget-costs-summary__value--total',
                summaryYouSave: 'c-product-widget-costs-summary__value--you-save',
                denomination: `${this.mainClass}__denomination`,
                denominationControls: `${this.mainClass}__denomination-controls`,
                denominationErrorMessage: `${this.mainClass}__denomination-error-message`,
                denominationLi: `${this.mainClass}__denomination-list-item`,
                denominationLink: DENOMINATION_LINK_CLASS,
                denominationLinkActive: `${DENOMINATION_LINK_CLASS}--active`,
                denominationLinkDisabled: `${DENOMINATION_LINK_CLASS}--disabled`,
                denominationList: `${this.mainClass}__denomination-list`,
                denominationSelect: `${this.mainClass}__denomination-select`,
                form: `${this.mainClass}__form`,
                heading: HEADING_CLASS,
                headingQuantity: `${HEADING_CLASS}--quantity`,
                mainErrorMessage: `${this.mainClass}__main-error-message`,
                nonJs: `${this.mainClass}--no-js`,
                quantityButton: QUANTITY_BUTTON_CLASS,
                quantityButtonDisabled: `${QUANTITY_BUTTON_CLASS}--disabled`,
                quantityControls: `${this.mainClass}__quantity-controls`,
                quantityFormRow: QUANTITY_FORM_ROW_CLASS,
                quantityFormRowNondenominated: `${QUANTITY_FORM_ROW_CLASS}-nondenominated`,
                quantityIndicator: `${this.mainClass}__quantity-indicator`,
                quantityInput: QUANTITY_INPUT_CLASS,
                quantityInputNondenominated: `${QUANTITY_INPUT_CLASS}--nondenominated`,
                quantityLabel: QUANTITY_LABEL_CLASS,
                quantityLabelNondenominated: `${QUANTITY_LABEL_CLASS}--nondenominated`,
                quantityLabelNondenominatedDisabled: `${QUANTITY_LABEL_CLASS}--nondenominated-disabled`,
                quantityMinus: `${QUANTITY_BUTTON_CLASS}--minus`,
                quantityPence: `${this.mainClass}__quantity-pence`,
                quantityPlus: `${QUANTITY_BUTTON_CLASS}--plus`,
                submit: SUBMIT_CLASS,
                submitDisabled: `${SUBMIT_CLASS}--disabled`,
            },
        };
    }

    /**
     * Store references to elements for later.
     */
    findSubElements() {
        this.$mainErrorMessage = this.$element.find(`.${this.config.classes.mainErrorMessage}`);

        this.$denominationControls = this.$element.find(`.${this.config.classes.denominationControls}`);
        this.$quantityControls = this.$element.find(`.${this.config.classes.quantityControls}`);

        this.$summaryTotal = this.$element.find(`.${this.config.classes.summaryTotal}`);
        this.$summaryRrp = this.$element.find(`.${this.config.classes.summaryRrp}`);
        this.$summaryYouSave = this.$element.find(`.${this.config.classes.summaryYouSave}`);

        this.$form = this.$element.find(`.${this.config.classes.form}`);
        this.$denominationSelect = this.$element.find(`.${this.config.classes.denominationSelect}`);
        this.$quantityFormRow = this.$element.find(`.${this.config.classes.quantityFormRow}`);
        this.$quantityInput = this.$element.find(`.${this.config.classes.quantityInput}`);
        this.$quantityLabel = this.$element.find(`.${this.config.classes.quantityLabel}`);
        this.$submit = this.$element.find(`.${this.config.classes.submit}`);
    }

    /**
     * Transform the user interface.
     */
    transformUi() {
        this.$element.removeClass(this.config.classes.nonJs);

        this.addDenominationUi();
        this.addQuantityUi();

        this.renderNoStockError();
        this.renderQuantityButtons();
        this.renderSubmitButton();

        this.setupEventHandlers();
        this.readQuantityFromInputAndSet();
    }

    /**
     * Setup the event handlers.
     */
    setupEventHandlers() {
        this.$form.submit(this.onFormSubmit.bind(this));

        if (this.model.isPredenominated()) {
            this.$quantityPlusButton.click(this.onQuantityPlusClick.bind(this));
            this.$quantityMinusButton.click(this.onQuantityMinusClick.bind(this));

            this.denominationLinks.forEach(($denominationLink) => {
                $denominationLink.click(this.onDenominationClick.bind(this));
            });
        } else {
            this.$quantityInput.keyup(this.onNondenominatedQuantityInputChange.bind(this));
        }
    }

    /**
     * Add the UI for selecting denominations.
     */
    addDenominationUi() {
        if (this.model.isPredenominated() && this.model.hasDenominations()) {
            const $heading = $(`<h3 class="${this.config.classes.heading}">${this.messages.chooseAmount}</h3>`);

            this.$denominationErrorMessage = $(`<p class="${this.config.classes.denominationErrorMessage}"></p>`);

            const $denominationList = $(`<ul class="${this.config.classes.denominationList}"></ul>`);

            // Display a list item and link for each denomination available.
            this.model.denominations.forEach((denomination) => {
                const disabledClass = !denomination.hasStock()
                    ? (` ${this.config.classes.denominationLinkDisabled}`)
                    : '';

                const $denominationLink = $(`<a href="#" class="${this.config.classes.denominationLink}${disabledClass
                }">${this.config.currencySymbol}${denomination.denomination}</a>`);

                $denominationLink.data(this.config.dataKeys.denomination, denomination);

                const $denominationLi = $(`<li class="${this.config.classes.denominationLi}"></li>`);

                $denominationLi.append($denominationLink);

                $denominationList.append($denominationLi);

                this.denominationLinks.push($denominationLink);
            });

            this.$denominationControls.append($heading)
                .append(this.$denominationErrorMessage)
                .append($denominationList);
        } else {
            /*
             * We're dealing with Nondenominated vouchers - there is no denomination selection involved, so just set the
             * active denomination and value of the denomination select form element to "0" (the nondenominated value).
             */
            this.$denominationSelect.val(0);
            const nondenominatedDenomination = this.model.getDenominationByDenominationAmount(0);

            if (typeof nondenominatedDenomination === 'undefined') {
                return;
            }

            this.model.activeDenomination = nondenominatedDenomination;
        }
    }

    /**
     * Set the active denomination.
     *
     * @param {Denomination} denomination A denomination.
     */
    setDenomination(denomination) {
        // Don't do anything if the user re-clicked the active denomination.
        if (this.model.activeDenomination === denomination) {
            return;
        }

        // Don't allow a denomination with no stock to be selected.
        if (!denomination.hasStock()) {
            return;
        }

        this.model.activeDenomination = denomination;

        this.renderDenominationLinks();
        this.hideNoDenominationSelectedError();

        this.setQuantity(0);
    }

    /**
     * Add the UI for selecting quantities.
     */
    addQuantityUi() {
        if (this.model.isPredenominated()) {
            const $heading = $(`<h3 class="${this.config.classes.heading} ${this.config.classes.headingQuantity
            }">${this.messages.quantity}</h3>`);

            this.$quantityMinusButton = $(
                `<a href="#" aria-label="Decrease Quantity" class="${this.config.classes.quantityButton} ${
                this.config.classes.quantityMinus} ${this.config.classes.quantityButtonDisabled}"><i class="fa fa-minus" aria-hidden="true"></i>
                    <span class="sr-only">Decrease quantity</span>
                </a>`
            );

            this.$quantityIndicator = $(`<span class="${this.config.classes.quantityIndicator}">0</span>`);

            this.$quantityPlusButton = $(
                `<a href="#" aria-label="Increase quantity" class="${this.config.classes.quantityButton} ${
                this.config.classes.quantityPlus}"><i class="fa fa-plus" aria-hidden="true"></i>
                    <span class="sr-only">Increase quantity</span>
                </a>`
            );

            this.$quantityControls.append($heading)
                .append(this.$quantityMinusButton)
                .append(this.$quantityIndicator)
                .append(this.$quantityPlusButton);
        } else {
            const $heading = $(`<h3 class="${this.config.classes.heading} ${this.config.classes.headingQuantity
            }">${this.messages.specifyAmount}</h3>`);

            const $quantityPence = $(
                `<span class="${this.config.classes.quantityPence}">${this.messages.pence}</span>`,
            );

            this.$quantityLabel.html(this.config.currencySymbol);

            this.$quantityFormRow.addClass(this.config.classes.quantityFormRowNondenominated);
            this.$quantityLabel.addClass(this.config.classes.quantityLabelNondenominated);
            this.$quantityInput.addClass(this.config.classes.quantityInputNondenominated);

            this.$quantityInput.after($quantityPence);

            this.$quantityControls.append($heading);
        }
    }

    /**
     * Set the quantity to a specific number.
     *
     * @param {number} quantity The quantity to set.
     */
    setQuantity(quantity) {
        this.model.quantity = quantity;

        this.renderQuantityChangeElements();

        this.hideNoStockForDenominationError();
    }

    /**
     * Read the value from the quantity input (nondenominated mode) and set the quantity accordingly.
     */
    readQuantityFromInputAndSet() {
        if (this.model.activeDenomination.hasStock()) {
            const value = this.$quantityInput.val();

            if (!/^\d*$/.test(value)) {
                this.setQuantity(0);
                this.displayNonIntegerQuantityError();

                return;
            }

            this.hideNonIntegerQuantityError();

            let intValue = parseInt(value, 10);

            // A value of '' will result in NaN here - set quantity to zero in that case.
            if (Number.isNaN(intValue)) {
                intValue = 0;
            }

            if (this.model.canQuantityBePurchased(intValue)) {
                this.setQuantity(intValue);
            } else {
                this.setQuantity(0);
                this.displayNoStockForNondenominatedError();
            }
        }
    }

    /**
     * Render the quantity buttons.
     */
    renderQuantityButtons() {
        if (this.model.isPredenominated()) {
            if (this.model.canQuantityBeIncremented() && this.model.hasStock()) {
                this.enableQuantityButton(this.$quantityPlusButton);
            } else {
                this.disableQuantityButton(this.$quantityPlusButton);
            }

            if (this.model.canQuantityBeDecremented() && this.model.hasStock()) {
                this.enableQuantityButton(this.$quantityMinusButton);
            } else {
                this.disableQuantityButton(this.$quantityMinusButton);
            }
        }
    }

    /**
     * Render the quantity indicator.
     */
    renderQuantityIndicator() {
        if (this.model.isPredenominated()) {
            this.$quantityIndicator.html(this.model.quantity);
            this.$quantityInput.val(this.model.quantity);
        }
    }

    /**
     * Update the costs and savings values to represent the currently selected denomination and quantity.
     */
    renderCostsAndSavings() {
        let total = 0;
        let rrp = 0;
        let saving = 0;

        if (this.model.activeDenomination !== null) {
            total = this.model.calculateDiscountedTotal();
            rrp = this.model.calculateRrp();
            saving = this.model.calculateSaving();
        }

        this.$summaryTotal.html(this.formatCurrency(total));
        this.$summaryRrp.html(this.formatCurrency(rrp));

        const youSave = `${this.formatCurrency(saving)} (${this.model.discountPercentage}%)`;
        this.$summaryYouSave.html(youSave);
    }

    /**
     * Enable or disable the submit button based on the current selection.
     */
    renderSubmitButton() {
        if (this.model.canBeSubmitted()) {
            this.$submit.removeClass(this.config.classes.submitDisabled);
        } else {
            this.$submit.addClass(this.config.classes.submitDisabled);
        }
    }

    /**
     * Render the no stock error message, if it applies.
     */
    renderNoStockError() {
        if (!this.model.hasStock()) {
            this.$mainErrorMessage.html(this.messages.outOfStock);

            if (this.model.isNondenominated()) {
                this.$quantityInput.attr('disabled', 'disabled');
                this.$quantityFormRow.addClass(this.config.classes.quantityLabelNondenominatedDisabled);
            }
        }
    }

    /**
     * Render the elements that change when quantities change.
     */
    renderQuantityChangeElements() {
        this.renderQuantityButtons();
        this.renderQuantityIndicator();
        this.renderCostsAndSavings();
        this.renderSubmitButton();
    }

    /**
     * Render the denomination links.
     */
    renderDenominationLinks() {
        if (this.model.isPredenominated()) {
            this.denominationLinks.forEach(($link) => {
                const denomination = $link.data(this.config.dataKeys.denomination);

                if (this.model.activeDenomination === denomination) {
                    $link.addClass(this.config.classes.denominationLinkActive);

                    this.$denominationSelect.val(denomination.denomination);
                } else {
                    $link.removeClass(this.config.classes.denominationLinkActive);
                }
            });
        }
    }

    /**
     * Display a currency amount.
     *
     * @param {number} amount The amount to display in pence.
     *
     * @return {string} A formatted currency amount.
     */
    formatCurrency(amount) {
        return this.config.currencySymbol + (amount / 100).toFixed(2);
    }

    /**
     * Enable a quantity button.
     *
     * @param {jQuery} $button The button to activate.
     */
    enableQuantityButton($button) {
        $button.removeClass(this.config.classes.quantityButtonDisabled);
    }

    /**
     * Disable a quantity button.
     *
     * @param {jQuery} $button The button to deactivate.
     */
    disableQuantityButton($button) {
        $button.addClass(this.config.classes.quantityButtonDisabled);
    }

    /**
     * Display an error message, telling the user they need to choose a denomination.
     */
    displayNoDenominationSelectedError() {
        this.$denominationErrorMessage.html(this.messages.noDenominationSelected);
    }

    /**
     * Hide the error message displayed when the user has not selected a denomination.
     */
    hideNoDenominationSelectedError() {
        this.$denominationErrorMessage.empty();
    }

    /**
     * Display an error message, telling the user they cannot add a denomination that has no stock.
     */
    displayNoStockForDenominationError() {
        this.$mainErrorMessage.html(this.messages.noStockForDenomination);
    }

    /**
     * Hide the error message displayed when the user attempts to add a denomination that has no stock.
     */
    hideNoStockForDenominationError() {
        this.$mainErrorMessage.empty();
    }

    /**
     * Display the error message when the user attempts to set a non-integer quantity.
     */
    displayNonIntegerQuantityError() {
        this.$mainErrorMessage.html(this.messages.quantityMustBeInteger);
    }

    /**
     * Hide the error message when the user attempts to set a non-integer quantity.
     */
    hideNonIntegerQuantityError() {
        this.$mainErrorMessage.empty();
    }

    /*
     * Display the error message when the user attempts to set a quantity above the available stock/limit.
     */
    displayNoStockForNondenominatedError() {
        this.$mainErrorMessage.html(this.messages.noStockForNondenominated);
    }

    /**
     * Hide the error message when the user attempts to set a quantity above the available stock/limit.
     */
    hideNoStockForNondenominatedError() {
        this.$mainErrorMessage.empty();
    }

    /**
     * Handler for clicks on denominations.
     *
     * @param {event} e The click event.
     */
    onDenominationClick(e) {
        e.preventDefault();

        const $denominationLink = $(e.target);
        const denomination = $denominationLink.data(this.config.dataKeys.denomination);

        this.setDenomination(denomination);
    }

    /**
     * Handler for clicks on the quantity plus button.
     *
     * @param {event} e The click event.
     */
    onQuantityPlusClick(e) {
        e.preventDefault();

        if (!this.model.hasStock()) {
            return;
        }

        if (this.model.activeDenomination === null) {
            this.displayNoDenominationSelectedError();

            return;
        }

        if (this.model.activeDenomination.canQuantityBePurchased(this.model.quantity + 1)) {
            this.setQuantity(this.model.quantity + 1);
        } else {
            this.displayNoStockForDenominationError();
        }
    }

    /**
     * Handler for clicks on the quantity minus button.
     *
     * @param {event} e The click event.
     */
    onQuantityMinusClick(e) {
        e.preventDefault();

        if (!this.model.hasStock()) {
            return;
        }

        if (this.model.activeDenomination === null) {
            this.displayNoDenominationSelectedError();

            return;
        }

        if (this.model.activeDenomination.canQuantityBePurchased(this.model.quantity - 1)) {
            this.setQuantity(this.model.quantity - 1);
        }
    }

    /**
     * Handler for change event on the quantity input for the nondenominated selector.
     *
     * @param {event} e The change event.
     */
    onNondenominatedQuantityInputChange(e) {
        this.readQuantityFromInputAndSet();
    }

    /**
     * Handler for form submission.
     *
     * @param {event} e The submit event.
     */
    onFormSubmit(e) {
        // Refresh the quantity value in case the form was submitted by pressing enter in the input (i.e. not clicking).
        if (this.model.isNondenominated()) {
            this.readQuantityFromInputAndSet();
        }

        if (!this.model.canBeSubmitted()) {
            e.preventDefault();
        }
    }
}
