$(() => {
    $('.js-open-modal')
        .on('click', (event) => {
            $(event.target)
                .closest('.w-modal')
                .find('.e-wallet-page-mask')
                .addClass('is-active');

            $(event.target)
                .closest('.w-modal')
                .find('.e-wallet-modal')
                .addClass('is-active');

            // We don't want the user
            // to start with a scrolled
            // modal so we scroll to the
            // top of the page.
            window.scrollTo(0, 0);
        });

    // IE11 does not support the CSS property object-fit so we use
    // background properties as a fallback.
    // https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
    if (!Modernizr.objectfit) {
        $('.c-e-wallet-table__media-img-container').each(function () {
            const $container = $(this);
            const url = $container.find('img').prop('src');

            if (url) {
                $container
                    .css('backgroundImage', `url(${url})`)
                    .addClass('compat-object-fit');
            }
        });
    }
});
