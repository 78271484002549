/**
 * Autocomplete
 *
 * Functionality for autocompletion of inputs.
 */

const COMPONENT_CLASS = 'c-autocomplete';
const LIST_ITEM_CLASS_ELEMENT = '__list-item';
const LIST_ITEM_ACTIVE_CLASS_MODIFIER = '--active';
const DATA_AUTOCOMPLETE_URL = 'autocompleteUrl';
const DATA_AUTOCOMPLETE_MIN_LENGTH = 'autocompleteMinLength';
const DATA_AUTOCOMPLETE_ENABLED = 'autocompleteEnabled';
const AUTOCOMPLETE_PLACEHOLDER = '{{query}}';
const DEFAULT_AUTOCOMPLETE_MIN_LENGTH = 3;
const KEYBOARD_SCROLLING_DEFAULT_INDEX = -1;
const KEYBOARD_UP_ARROW_KEY_NUMBER = 38;
const KEYBOARD_DOWN_ARROW_KEY_NUMBER = 40;
const KEYBOARD_ENTER_KEY_NUMBER = 13;

document.addEventListener('DOMContentLoaded', function() {
    const autocompletes = document.querySelectorAll(`.${COMPONENT_CLASS}`);

    autocompletes.forEach((autocomplete) => {
        const enabledData = autocomplete.dataset[DATA_AUTOCOMPLETE_ENABLED];
        const enabled = enabledData === undefined ? false : enabledData;

        if (!enabled) {
            return;
        }

        const input = autocomplete.querySelector(`.${COMPONENT_CLASS}__input`);

        const minLengthData = autocomplete.dataset[DATA_AUTOCOMPLETE_MIN_LENGTH];
        const minLength = minLengthData === undefined ? DEFAULT_AUTOCOMPLETE_MIN_LENGTH : parseInt(minLengthData, 10);

        let ul = document.createElement('ul');
        ul.className = `${COMPONENT_CLASS}__list`;

        // Keyboard scrolling - currently active li
        let currentActiveLi = KEYBOARD_SCROLLING_DEFAULT_INDEX;

        /**
         * Clear the suggestions.
         */
        const clearResults = function () {
            if (autocomplete.contains(ul)) {
                ul.innerHTML = ''; // Remove any previously added list items.
                ul = autocomplete.removeChild(ul);
                currentActiveLi = KEYBOARD_SCROLLING_DEFAULT_INDEX; // Reset keyboard scrolling index.
            }
        };

        /**
         * Render the suggestions.
         *
         * @param {Object} data The suggestions data.
         */
        const renderResults = function (data) {
            if (data.results !== undefined) {
                data.results.forEach((result) => {
                    const li = document.createElement('li');
                    li.textContent = result;
                    li.className = COMPONENT_CLASS + LIST_ITEM_CLASS_ELEMENT;

                    li.onclick = () => {
                        input.value = li.textContent;
                        clearResults();
                        input.closest('form').submit();
                    };

                    ul.appendChild(li);
                });

                autocomplete.insertBefore(ul, input.nextSibling); // Add the list to the DOM after the input.
            }
        };

        // Set up an event to make an AJAX request to retrieve suggestions on keyup.
        input.addEventListener('keyup', (event) => {
            const keyNumber = event.keyCode;

            if (keyNumber !== KEYBOARD_DOWN_ARROW_KEY_NUMBER && keyNumber !== KEYBOARD_UP_ARROW_KEY_NUMBER) {
                const { value } = input;

                let url = autocomplete.dataset[DATA_AUTOCOMPLETE_URL];
                url = url.replace(AUTOCOMPLETE_PLACEHOLDER, value);

                if (value.length >= minLength) {
                    $.ajax({
                        url,
                        success: (data) => {
                            clearResults();
                            renderResults(data);
                        },
                        fail: () => {
                            clearResults();
                        },
                    });
                } else {
                    clearResults();
                }
            }
        });

        // Add key binding to scroll up, down and select autocomplete option with enter.
        input.addEventListener('keydown', (e) => {
            const liCollection = ul.getElementsByTagName('li');

            // Act only if li elements are added to autocomplete.
            if (liCollection.length > 0) {
                switch (e.keyCode) {
                case KEYBOARD_UP_ARROW_KEY_NUMBER:
                    currentActiveLi--;
                    if (currentActiveLi < 0) {
                        currentActiveLi = liCollection.length - 1;
                    }
                    break;

                case KEYBOARD_DOWN_ARROW_KEY_NUMBER:
                    currentActiveLi++;
                    if (currentActiveLi > liCollection.length - 1) {
                        currentActiveLi = 0;
                    }
                    break;

                case KEYBOARD_ENTER_KEY_NUMBER:
                    liCollection.item(currentActiveLi).click();
                    return; // exit this handler after form submission

                default: return; // exit this handler for other keys
                }

                for (let i = 0; i < liCollection.length; i++) {
                    liCollection[i].classList.remove(
                        COMPONENT_CLASS + LIST_ITEM_CLASS_ELEMENT + LIST_ITEM_ACTIVE_CLASS_MODIFIER,
                    );
                }

                liCollection.item(currentActiveLi).classList.add(
                    COMPONENT_CLASS + LIST_ITEM_CLASS_ELEMENT + LIST_ITEM_ACTIVE_CLASS_MODIFIER,
                );
                e.preventDefault();
            }
        });

        // Set up a click event to clear the suggestions if a click occurs elsewhere on the page.
        document.addEventListener('click', (event) => {
            if (!autocomplete.contains(event.target)) {
                clearResults();
            }
        });
    });
});
