$(() => {
    const closeModal = (event) => {
        $(event.target)
            .closest('.w-modal')
            .find('.e-wallet-page-mask')
            .removeClass('is-active');

        $(event.target)
            .closest('.w-modal')
            .find('.e-wallet-modal')
            .removeClass('is-active');
    };

    const openCardDetailModal = (event) => {
        $($(event.target).data('target')).click();
    };

    $('.e-wallet-modal__header-icon')
        .click((event) => {
            closeModal(event);
        });

    $('.e-wallet-page-mask')
        .click((event) => {
            closeModal(event);
        });

    $('.e-wallet-modal__add-card-details-link')
        .click((event) => {
            closeModal(event);
            openCardDetailModal(event);
        });

    $('.e-wallet-modal_add-card-detail-button')
        .click((event) => {
            closeModal(event);
            openCardDetailModal(event);
        });
});
