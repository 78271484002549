/**
 * Product widget container.
 */
export default class ProductWidgetContainerView {
    /**
     * Constructor.
     *
     * @param {HTMLElement} element The main element.
     * @param {string} mainClass The main class.
     */
    constructor(element, mainClass) {
        this.element = element;
        this.mainClass = mainClass;

        this.generateConfig();
        this.findSubElements();
        this.setupEventHandlers();
    }

    /**
     * Generate some configuration data.
     */
    generateConfig() {
        const INNER_CLASS = `${this.mainClass}__inner`;
        const MOBILE_OPEN_LINK_CLASS = `${this.mainClass}__mobile-open-link`;
        const MOBILE_OPEN_LINK_CONTAINER_CLASS = `${this.mainClass}__mobile-open-link-container`;

        this.config = {
            classes: {
                inner: INNER_CLASS,
                innerMobileClosed: `${INNER_CLASS}--mobile-closed`,
                mobileCloseLink: `${this.mainClass}__mobile-close-link`,
                mobileOpenLink: MOBILE_OPEN_LINK_CLASS,
                mobileOpenLinkContainer: MOBILE_OPEN_LINK_CONTAINER_CLASS,
                mobileOpenLinkContainerHidden: `${MOBILE_OPEN_LINK_CONTAINER_CLASS}--hidden`,
                mobileOpenLinkDisabled: `${MOBILE_OPEN_LINK_CLASS}--disabled`,
            },
        };
    }

    /**
     * Store references to elements for later.
     */
    findSubElements() {
        this.inner = this.element.querySelector(`.${this.config.classes.inner}`);

        this.mobileOpenLinkContainer = this.element.querySelector(`.${this.config.classes.mobileOpenLinkContainer}`);
        this.mobileOpenLink = this.element.querySelector(`.${this.config.classes.mobileOpenLink}`);

        this.mobileCloseLink = this.element.querySelector(`.${this.config.classes.mobileCloseLink}`);
    }

    /**
     * Setup the event handlers.
     */
    setupEventHandlers() {
        this.mobileOpenLink.addEventListener('click', this.onMobileOpenLinkClick.bind(this));
        this.mobileCloseLink.addEventListener('click', this.onMobileCloseLinkClick.bind(this));
    }

    /**
     * Render the mobile user interface.
     *
     * @param {bool} open True if the interface should be open.
     */
    renderMobileUserInterface(open) {
        if (open) {
            this.inner.classList.remove(this.config.classes.innerMobileClosed);

            this.mobileOpenLinkContainer.classList.add(this.config.classes.mobileOpenLinkContainerHidden);
        } else {
            this.inner.classList.add(this.config.classes.innerMobileClosed);

            this.mobileOpenLinkContainer.classList.remove(this.config.classes.mobileOpenLinkContainerHidden);
        }
    }

    /**
     * Handler for clicks on the mobile open link.
     *
     * @param {event} e The click event.
     */
    onMobileOpenLinkClick() {
        if (!this.mobileOpenLink.classList.contains(this.config.classes.mobileOpenLinkDisabled)) {
            // Don't call e.preventDefault() here, so we scroll to the top of the form.
            this.renderMobileUserInterface(true);
        }
    }

    /**
     * Handler for clicks on the mobile close link.
     *
     * @param {event} e The click event.
     */
    onMobileCloseLinkClick(e) {
        e.preventDefault();

        this.renderMobileUserInterface(false);
    }
}
