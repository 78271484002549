import $ from '../../../../assets/js/common/jquery';

const config = {
    showMore: '.order-history-detail__show-more',
    item: '.order-history-detail__item',
};

class orderHistoryDetail {
    /**
     * Constructor for orderHistoryDetail
     */
    constructor() {
        this.showMore = $(config.showMore);
        this.item = $(config.item);

        this.bindHistoryDetailToggle()
    }

    bindHistoryDetailToggle() {
        this.showMore.on('click', '', function () {
            const $this = $(this);
            if ($this.parent().find('.order-history-detail__item').hasClass('is-shown')) {
                $this.parent().find('.order-history-detail__item.is-shown').addClass('is-hidden').removeClass('is-shown');
                $this.find('.order-history-detail__show-more-icon').removeClass('has-opened')
                $this.find('.order-history-detail__show-more-text').text($this.find('.order-history-detail__show-more-text').data('openText'))
            } else if ($this.parent().find('.order-history-detail__item').hasClass('is-hidden')) {
                $this.parent().find('.order-history-detail__item.is-hidden').removeClass('is-hidden').addClass('is-shown');
                $this.find('.order-history-detail__show-more-icon').addClass('has-opened')
                $this.find('.order-history-detail__show-more-text').text($this.find('.order-history-detail__show-more-text').data('closeText'))
            }
        })

    }
}

$(() => {
    // Initiate onboarding class
    new orderHistoryDetail();
});