/**
 * Value object to represent a denomination.
 */
export default class Denomination {
    /**
     * Constructor.
     *
     * @param {string} denomination The denomination.
     * @param {string} quantityAvailable The quantity available.
     */
    constructor(denomination, quantityAvailable) {
        this.denomination = denomination;
        this.quantityAvailable = quantityAvailable;
    }

    /**
     * Check if a quantity can be purchased.
     *
     * @param {number} quantity A quantity to check.
     *
     * @return {bool} True if the quantity could be purchased (zero is a valid amount).
     */
    canQuantityBePurchased(quantity) {
        return Number.isInteger(quantity) && quantity >= 0 && quantity <= this.quantityAvailable;
    }

    /**
     * Determine if this denomination has stock available.
     *
     * @return {bool} True if there is stock available.
     */
    hasStock() {
        return this.quantityAvailable > 0;
    }

    /**
     * Get the "unit value" for a denomination in pence.
     *
     * This will typically be the value of the "denomination" property, with the exception of when nondenominated
     * vouchers are involved, which have a "denomination" value of 0. To work around this, these will have a value of 1,
     * so we can better calculate total values when we're multiplying by quantities etc.
     *
     * @return {number} The unit value of the denomination in pence.
     */
    getUnitValue() {
        return this.denomination === 0 ? 100 : (this.denomination * 100);
    }
}
