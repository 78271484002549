/**
 * Postcode lookup allowing the user to select from multiple addresses.
 *
 * Functionality is triggered by clicking on elements with a "postcode-lookup-multi-address" class. The element should
 * also have the following data attributes set:
 *
 * data-url: The URL that will be requested to return address data.
 *
 * data-params: A JSON string representing an object with URL parameters as keys and DOM selectors as values. The keys
 *              specify the URL parameters that should be added to the AJAX request (e.g. "postcode"), the values are
 *              the DOM selectors where the values should be obtained from (e.g. "#postcode").
 *
 * data-outputs: A JSON string representing an object with fields as keys and DOM selectors as values. The keys specify
 *               the properties of the AJAX response (e.g. "county"), the values specify the DOM selectors of the inputs
 *               that should be updated with the values from the response (e.g. "#county").
 *
 * data-address-field: A jQuery selector pointing at the select element that will be used to allow the user to select an
 *               address.
 *
 * data-expanded-row-count: When a user has entered a post code and clicked the button, the address select will expand -
 *               this allows the user to see that their submission took effect. This parameter controls the number of
 *               options that will be displayed when the address select is expanded.
 */

import $ from '../../../../assets/js/common/jquery';


const SELECTOR = '.postcode-lookup-multi-address';

const DATA_URL = 'url';
const DATA_ADDRESS = 'address';
const DATA_ADDRESS_FIELD = 'address-field';
const DATA_PARAMS = 'params';
const DATA_OUTPUTS = 'outputs';
const DATA_EXPANDED_ROW_COUNT = 'expanded-row-count';

$(() => {
    const $buttons = $(SELECTOR);

    $buttons.each((index, element) => {
        const $element = $(element);

        $element.click((event) => {
            event.preventDefault();

            const url = $element.data(DATA_URL);

            // Map AJAX parameters to selectors: key is the URL param, selector is the source of the parameter value.
            const params = $element.data(DATA_PARAMS);

            // Map fields to selectors: the key is a property of the AJAX response, selector is the element to update.
            const outputs = $element.data(DATA_OUTPUTS);

            const addressField = $element.data(DATA_ADDRESS_FIELD);

            const expandedRowCount = $element.data(DATA_EXPANDED_ROW_COUNT);

            // Ensure we have a URL, a URL parameter map and an output map, otherwise we cannot do anything.
            if (url === undefined || params !== Object(params) || outputs !== Object(outputs)) {
                return false;
            }

            // Get the parameter values we'll need so we can make an AJAX request.
            const paramValues = {};
            for (const urlParam in params) {
                const value = $(params[urlParam]).val();

                paramValues[urlParam] = value;
            }

            if (!paramValues.postcode) {
                alert('Please provide a postcode.');
            } else {
                $.ajax(
                    {
                        url,
                        type: 'GET',
                        data: paramValues,
                        success: (responseData) => {
                            if (
                                responseData.hasOwnProperty('addresses')
                                && Array.isArray(responseData.addresses)
                                && responseData.addresses[0] !== undefined
                            ) {
                                /*
                                 * Response will contain an array of addresses - assign them to options in the select.
                                 */
                                const addressCount = responseData.addresses.length;

                                const $addressSelect = $(addressField);

                                const $placeholder = $addressSelect.find('option[value=""]');

                                $addressSelect.empty();

                                $addressSelect.append($placeholder);

                                // Set up an <option> for each address, storing the address as data on the option.
                                for (let i = 0; i < addressCount; i++) {
                                    const addressData = responseData.addresses[i];

                                    const option = $(`<option value="${i}">${addressData.line1}</option>`);

                                    option.data(DATA_ADDRESS, addressData);

                                    $addressSelect.append(option);
                                }

                                // Expand the select so it's clear there are now addresses available to choose from.
                                $addressSelect.attr('size', Math.min(addressCount + 1, expandedRowCount));

                                $addressSelect.change(() => {
                                    // Retrieve the data from the selected option and populate the output fields.
                                    const selectedOption = $addressSelect.find(':selected');

                                    const addressData = selectedOption.data(DATA_ADDRESS);

                                    // Blank all outputs, then fill in any that have mappings in the address data.
                                    for (const i in outputs) {
                                        $(outputs[i]).val('');
                                    }

                                    for (const field in addressData) {
                                        if (outputs[field] !== undefined) {
                                            $(outputs[field]).val(addressData[field]);
                                        }
                                    }

                                    // Contract the select so the selected address is displayed
                                    $addressSelect.removeAttr('size');
                                });
                            }
                        },
                        error: () => {
                            alert('There was a problem: please complete the address manually.');
                        },
                    },
                );
            }
        });
    });
});
